import "./initials.scss";
export const Initials = (props: {imgUrl?: string, name: string, title?: string, onClick: ()=> void,className?:string}) => {
    const getInitials = (name: string) => {
        const tokens = name.split(" ");
        if (tokens.length > 1) {
            return `${tokens[0].charAt(0)}${tokens[1].charAt(0)}`
        } else if (tokens.length === 1 && tokens[0].length >1) {
            return `${tokens[0].charAt(0)}${tokens[0].charAt(1)}`;
        } else if (tokens.length === 1) {
            return `${tokens[0].charAt(0)}`;
        } else
            return '';
    }
    return <div className={`initials cursor-pointer ${props?.className}`} onClick={()=> props.onClick()} title={props.title}>
        {props.imgUrl?<img src={props.imgUrl} />:<span>{getInitials(props.name)}</span>}
    </div>
}