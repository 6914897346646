import React, {useRef, useState} from "react";
import "./imageUpload.scss"
import {useApiClient} from "../../network/client/UseApiClient";
import {uploadImageClient} from "../../services/ClientProjectService";
import {v4} from "uuid";
import {useToast} from "../toast/ToastManager";

export const ImageUpload = (props: {
    onDrop?: (value: boolean) => void, clientId: string, projectId: string, onDone: (data: any) => void, onError: (data: any) => void,
    onFileChange?: (file: File | null) => void
}) => {
    const [images, imagesNetworkState, error, postImages] = useApiClient<any>();
    const imageChooser = useRef<HTMLInputElement>(null);
    const [dragEnter, setDragEnter] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null);
    const toast = useToast();

    const handleDragEvent = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            setFile(selectedFiles[0])
            props.onFileChange && props.onFileChange(selectedFiles[0])
            uploadFiles(selectedFiles[0]);
        }

    }
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setFile(e.dataTransfer.files[0])
            uploadFiles(e.dataTransfer.files[0])
            e.dataTransfer.clearData();
            setDragEnter(false);
        }
    }
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        handleDragEvent(e)
    }
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        setDragEnter(false);
    }


    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        handleDragEvent(e)
    }
    const uploadFiles = (file: File | null) => {
        if (!file) {
            alert('Please select file to upload');
            return;
        } else {
            postImages(uploadImageClient(props.projectId, props.clientId ,'IMG', file!)).then(res => {
                props.onDone(res);
                toast.show({id: v4(), title: "Uploaded Successfully", duration: 2000})
            }).catch(err=>{
                props.onError(err);
                if(err.response.status !== 403){
                    toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})
                }
            })
        }
    }

    return (
        <div className={`imageUploadWrapper col`}>
            <div className={`d-flex align-items-center justify-content-center flex-column  imageContainer py-2 `}
                 style={{borderColor: dragEnter ? "blue" : "#ccc"}}
                 onClick={() => imageChooser?.current?.click()} onDrop={handleDrop} onDragOver={handleDragOver}
                 onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
                <div>
                    <i className="bi bi-file-earmark-image fs-3"></i>
                </div>
                <div className={'mt-1 d-flex flex-column'}>
                    <span className={`droptext`}>Drop files here</span>
                    <span className={` text-center   `}>OR</span>
                </div>
                <div>
                    <button className={' btn btn-sm btn-secondary m-1'}>Browse Images</button>
                </div>
            </div>
            <input type={'file'} ref={imageChooser} name={'file'} id="fileInput" multiple
                   accept="image/jpg,image/jpeg,image/png" onChange={handleFileChange} hidden/>
        </div>
    )
}
export default ImageUpload;