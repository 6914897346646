import {ApiContract} from "../network/dto/ApiContract";

const FetchApprovalRequests = (): ApiContract => {
    return {url: `/super_admin/approval_requests`, method: "GET"}
}
const ApproveRequest = (requestId: number): ApiContract => {
    return {url: `/requests/${requestId}/approve`, method: "POST"}
}
const RejectRequest = (requestId: number): ApiContract => {
    return {url: `/requests/${requestId}/reject`, method: "POST"}
}
export {FetchApprovalRequests, ApproveRequest, RejectRequest}