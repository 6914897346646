import {useInputHandler} from "../../../../../../../../hooks/UseInputHandler";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import {Button} from "../../../../../../../../components/button/Button";
import {NetworkState} from "../../../../../../../../network/dto/ApiContract";
import {useApiClient} from "../../../../../../../../network/client/UseApiClient";
import {Option, SelectDropDown} from "../../../../../../../../components/selectDropdown/SelectDropDown";
import {useParams} from "react-router";
import {
    addUserToDocument,
    getAllDocumentUser,
    removeUserFromDocument
} from "../../../../../../../../services/DocumentService";
import {useToast} from "../../../../../../../../components/toast/ToastManager";
import {v4} from "uuid";
import {getProjectUserApiContract} from "../../../../../../../../services/UserService";
import ConfirmationModal from "../../../../../../../../components/confirmationDialog/ConfirmationModal";

export const AddMemberToDocument = (props: {
    show: boolean,
    onCancel: (shouldRefresh?: boolean) => void,
    onSuccess: (shouldRefresh?: boolean) => void,
    defaultValue?: string,
    id?: string
}) => {


    const {payload, handleChange, setValue} = useInputHandler<{ clientId?: string[], userId: string }>({
        clientId: [],
        userId: ''
    });
    const [members, memberNetworkState, error, postMember] = useApiClient<any[]>();
    const [getMembers, getMembersNetworkState, getError, getAllMembers] = useApiClient<any>();
    const [removeDocumentMember, getRemoveMemberNetworkState, getRemoveMemberError, removeDocMember] = useApiClient<any>();
    const [getDocMembers, getDocMembersNetworkState, getDocError, getAllDocMembers] = useApiClient<any>();
    // const [getMembers, getMembersNetworkState, getError,getAllMembers] = useApiClient<DocumentMember[]>();
    const [selectedMember, setSelectedMember] = useState<number[]>([])
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const toast = useToast();

    const params = useParams<{ projectId: string }>();


    const submitForm = (event: FormEvent) => {
        event.preventDefault();
        postMember(addUserToDocument(props?.id || '', payload.clientId || [])).then(res => {
            console.log('id',payload.clientId);
            toast.show({id: v4(), title: 'New member added to the document', duration: 2000});
            getAllDocMembers(getAllDocumentUser(props.id || ""));
            getAllMembers(getProjectUserApiContract((params?.projectId || ""), props.id || ''));
            props.onSuccess(true);
            setSelectedMember([]);
        }).catch(err => {
            if (err.response.status !== 403) {
                toast.show({id: v4(), title: "Fail to add", duration: 2000, isSuccess: false});
            }
        })
    }

    useEffect(() => {
        setValue('userId', '');
        getAllDocMembers(getAllDocumentUser(props.id || ""));
        getAllMembers(getProjectUserApiContract((params?.projectId || ""), props.id || ''));

    }, []);


    const onMemberChange = (options: Option[]) => {
        if (options) {
            setSelectedMember(options.map(option => option.value))
            setValue('clientId', options.map(option => option.value));
        } else setSelectedMember([])

    }

    const removeMember =(id:string)=>{
        removeDocMember(removeUserFromDocument(props?.id || '',id)).then((res)=>{
            setShowConfirmationDialog(false);
            // toast.show({id: v4(), title: res.message, duration: 2000, isSuccess: true});
            toast.show({id: v4(), title: "Removed User", duration: 2000, isSuccess: true});
            getAllDocMembers(getAllDocumentUser(props.id || ""));
            getAllMembers(getProjectUserApiContract((params?.projectId || ""), props.id || ''));
            props.onSuccess(true);

        }).catch((err)=>{
            if(err.response.status !== 403){
            setShowConfirmationDialog(false);
            toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false});

        }});

    }

    const handleChange2 = (evt: ChangeEvent<HTMLElement>) => {

    }


    return <div>
        <Modal show={props.show} onHide={props.onCancel} centered style={{}} className={'modal documentModal'}>
            <Modal.Header closeButton className="border-0 pb-0">
                <h6 className={'fw-bolder'}>{"Document Members"}</h6>
            </Modal.Header>
            <ModalBody className="modal-content rounded-0 border-0 overflow-visible ">
                <div>
                {getDocMembers?.map((member: any) => (
                    <span className="badge rounded-pill bg-primary me-2">{member.fullname || ''}<i className="bi bi-x" onClick={()=>{
                        removeMember(member.id || '');
                        // setShowConfirmationDialog(true);
                    }}></i></span>))}
                    </div>

               <div className={'mt-4'}>
                   {/*<span>Add Member To Document</span>*/}
                    <form id={`form_member`} className={'flex-fill'} onSubmit={submitForm}>
                    <div className="form-group mt-2 mb-6">
                        <label className={"text-secondary fw-bold"}>Add Member To Document</label>
                        <SelectDropDown values={selectedMember} options={getMembers?.map((member: any) => ({
                            label: member?.fullname,
                            value: member?.id
                        }))} onSelect={onMemberChange} label={'select topic'} placeholders={'select'}/>
                    </div>
                </form>

                </div>
        </ModalBody>
        <ModalFooter className={'d-flex justify-content-end border-0'}>
            <Button type={'button'} className={'btn btn-outline-primary py-0 px-3  small fw-bold'}
                    onClick={() => props.onCancel(false)}>Cancel
            </Button>
            <Button type={'submit'} loading={memberNetworkState === NetworkState.Loading} form={`form_member`}
                    loaderText={`...saving`} className={'btn btn-primary py-0 px-3 small'}>Add</Button>
        </ModalFooter>
            <ConfirmationModal title={"Delete Member"} message={"Are you sure you want to delete this Member?"} onSubmit={()=>{
                // removeMember();
            }} onCancel={()=> setShowConfirmationDialog(false)} show={showConfirmationDialog} btnText={"Remove"}/>
    </Modal>


</div>
}
