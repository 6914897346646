import sortOrder from '../../../../../../assets/icons/sort_order.svg';
import './projectSetting.scss'
import React, {useEffect, useState} from "react";
import {useApiClient} from "../../../../../../network/client/UseApiClient";
import {Documents} from "../../../../../../models/Documents";
import {useParams} from "react-router";
import {NetworkState} from "../../../../../../network/dto/ApiContract";
import {Spinner} from "../../../../../../components/spinner/Spinner";
import {Integration} from "../../../../../../models/Integration";
import {GetAllIntegration} from "../../../../../../services/IntegrationService";
import {IntegrationFormModal} from "./integrationModal/IntegrationFormModal";
import IntegrationListCard from "./settingList/IntegrationList";
import {EmptyState} from "../../../../../../components/emptyState/EmptyState";
import {v4} from "uuid";
import {toast} from "../../../../../../components/toast/ToastManager";

export const Integrations = () => {
    const [showModal,setShowModal] = useState<boolean>(false);
    const [showSyncModal,setShowSyncModal]=useState<boolean>(false);
    const [integration, integrationNetworkState, error, fetchIntegration] = useApiClient<Integration[]>();
    const params=useParams<{projectId: string}>();

    useEffect(()=>{
        fetchIntegration(GetAllIntegration()).catch(err=> {
            if (err.response.status !== 403)
                toast.show({id: v4(), title: `Error: ${err.toString()}`, duration: 5000, isSuccess: false})
        })
    },[params?.projectId]);

    return <div className={'my-4'}>
        <p className={`mb-4`}>
            Determine what data is synchronized, how often it happens, and what actions are taken in the event of conflicts.<br/>
            Ensure data consistency and accuracy across integrated platforms.
        </p>
        <div className={`documentList`}>
            <div className={`d-flex mb-4`}>
                <button className={`btn btn-primary me-2`} onClick={()=>setShowModal(true)}>+ New Integration</button>
                <div className={`ms-3 d-flex align-items-center`} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <img src={sortOrder} className={'me-2'}/>
                    <small>Sort by: Most Recent</small>
                </div>
            </div>
            <div>
                <div className={`row mb-3`}><span className={`text-secondary col-6 col-sm-3 col-md-3 col-lg-2 col-xl-1`}>Sync status</span> <span className={`col`}>The last sync from your drive ran successfully on 11/1/2023 at 1:08:12 PM</span></div>
                <div className={`row`}><span className={`text-secondary col-6 col-sm-3 col-md-3 col-lg-2 col-xl-1`}>Sync frequency</span> <div className={`col`}>
                    <div className={`bg-white rounded px-4 py-3`}>
                        <div className={`mb-2`}>
                            <input id={`sync_schedule_realtime_10min`} type={"radio"} name={`sync_schedule`} value={"realtime_10min"} checked/>
                            <label htmlFor={`sync_schedule_realtime_10min`} className={`ms-2`}>Near Real time (10 min)</label>
                        </div>
                        <div className={`mb-2`}>
                            <input id={`sync_schedule_custom`} type={"radio"} name={`sync_schedule`} value={"custom"} />
                            <label htmlFor={`sync_schedule_custom`} className={`ms-2`}>Manually schedule sync</label>
                        </div>
                        <div>
                            <input id={`sync_off`} type={"radio"} name={`sync_schedule`} value={"sync_off"} />
                            <label htmlFor={`sync_off`} className={`ms-2`}>Turn off sync </label>
                        </div>
                    </div>

                </div></div>
            </div>

            {integrationNetworkState=== NetworkState.Loading?<div className={`w-100 d-flex justify-content-center`}>
                <Spinner/>
            </div>:<div className={'settings mt-4 me-4'}>

                <div className={`row`}>
                    <div className={`text-secondary col-6 col-sm-3 col-md-3 col-lg-2 col-xl-1`}>
                        Integrations
                    </div>
                    <div className={`col`}>
                        {integration?.length ?
                            <div className="row row-cols-2 g-4">{integration?.map((detail: Integration) =>
                                <div className={`col`}><IntegrationListCard defaultValue={detail} onSuccess={()=> fetchIntegration(GetAllIntegration())} onDelete={()=>fetchIntegration(GetAllIntegration())}/></div>
                            )}
                            </div> : <div className={'container bg-white h-100'}><EmptyState/></div>}
                    </div>
                </div>
            </div>}
        </div>

        <IntegrationFormModal show={showModal} onCancel={()=> {
            setShowModal(false);
        }} onSuccess={()=>{
            setShowModal(false);
            // if (refresh) {
            fetchIntegration(GetAllIntegration())
            // }
        }}/>

        {/*<SyncModal show={showSyncModal} onCancel={()=>setShowSyncModal(false)} onSuccess={()=>setShowSyncModal(false)}/>*/}
    </div>
}