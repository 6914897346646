import React from "react";
import {Drawing, Object} from "../../../models/Drawing";
import rvtIcon from "../../../assets/icons/rvt.png";

export const DrawingCard = (props: {drawing: Drawing, onClick: (evt: React.MouseEvent)=> void}) => {
    // const content: any = JSON.parse(props.drawing?.content ||'');
    return <div className={`drawingCard`}>
        <div className={`header`} onClick={props.onClick}><img className={`icon`} src={rvtIcon}/></div>
        <div className={`body`}>
            {/*<small>{content.name || ''}</small>*/}
            {/*<button className={`btn`}><span className={`bi bi-share`}></span></button>*/}
        </div>
    </div>;
}