import Img from '../../../../../../../assets/images/project_placeholder.png';
import React, {useState} from "react";
import './projectpartnerListCard.scss';
import {Client} from "../../../../../../../models/Client";
import ConfirmationModal from "../../../../../../../components/confirmationDialog/ConfirmationModal";
import {useApiClient} from "../../../../../../../network/client/UseApiClient";
import {Documents} from "../../../../../../../models/Documents";
import {DropdownButton, DropdownItem} from "react-bootstrap";
import menu from "../../../../../../../assets/icons/menu.svg";
import {AddNewPartner} from "../addNewPartner/AddNewPartner";
import {useToast} from "../../../../../../../components/toast/ToastManager";
import {v4} from "uuid";
import {RemovePartner} from "../../../../../../../services/PartnerService";
import {PartnerDocs} from "../../../../../../../components/partner_docs/PartnerDocs";

export const ProjectPartnerListCard = (props: {
    projectId: string,
    data?: Client,
    isActive: boolean,
    onClick: (show?: boolean) => void,
    onDelete: () => void,
    onSuccess: () => void
}) => {

    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [partner, partnerNetworkState, error, partnerData] = useApiClient<Documents>();
    const toast = useToast();
    const [targetPartner, setTargetPartner] = useState<string | undefined>(undefined);


    /*useEffect(()=>{
        setTargetClient(props?.data?.id || '')
    },[])*/
    const removePartner = () => {
        partnerData(RemovePartner(props?.data?.id || '')).then(() => {
            toast.show({id: v4(), title: "Deleted Successfully", duration: 2000})
            props.onDelete();
        }).catch((err) => {
            console.log('err', err)
            if (err.response.status !== 403) {
                toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})
            }

        });


    }

    return <div className={`clientCard mb-1 ${props.isActive ? 'active' : ''}`} onClick={() => {
        if (!props.isActive)
            props.onClick(true)
    }}>
        <div className={`d-flex justify-content-between`}>
            <div className={`d-flex align-items-center`}>
                <img src={Img} className={'imgThumbnail me-2'}>
                </img>
                <small className={`fw-bold`}>{props?.data?.name}</small>
                <small className={'ms-2'}>{props?.data?.project?.name || ''}</small>
                <p></p>
            </div>
            {props.isActive ? <div className={'d-flex'}>
                <button className={`btn btn-sm`} onClick={(event) => {
                    event.stopPropagation();
                    props.onClick(false);
                }}>
                    <span className={`bi bi-dash-lg text-dark fw-bold`}/></button>

                <div className={'border-0'}><DropdownButton
                    // drop={`start`}
                    title={<img src={menu} onClick={() => ''}/>}
                    className={`text-white`}>
                    <DropdownItem className={``} onClick={() => {
                        setTargetPartner(props.data?.id);
                        setShowEditDialog(true);
                    }}><span className={`bi bi-pencil me-2`}/>Edit</DropdownItem>
                    <DropdownItem className={`text-danger`} onClick={() => setShowConfirmationDialog(true)}><span
                        className={`bi bi-trash me-2 text-danger`}/> Delete</DropdownItem>
                </DropdownButton></div>

                {/*<span className={`bi bi-trash me-2 text-danger`} onClick={()=>setShowConfirmationDialog(true)}/>*/}
            </div> : null}
        </div>

        {props.isActive ? <div className={`w-100 px-5 py-3 pb-5`} onClick={(event) => {
            event.stopPropagation()
        }}>
            <PartnerDocs projectId={props.projectId} partnerId={props.data?.id || ''}/>
        </div> : null}

        <ConfirmationModal title={'Delete Partner'} message={'Are you sure you want to delete this Partner?'}
                           onSubmit={() => {
                               removePartner();
                               setShowConfirmationDialog(false)
                           }} onCancel={() => setShowConfirmationDialog(false)} show={showConfirmationDialog}
                           btnText={'Delete'}/>
        <AddNewPartner show={showEditDialog} onCancel={() => setShowEditDialog(false)} onSuccess={() => {
            setShowEditDialog(false);
            setTargetPartner(undefined)
            props?.onSuccess();
        }} defaultValue={targetPartner}/>


    </div>
}