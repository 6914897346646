import {ApiContract} from "../network/dto/ApiContract";

const uploadDocument = (projectId: string, file: File, name: string, description: string): ApiContract => {
    const formData = new FormData();
    formData.append('file', file);
    if (name)
        formData.append('name', name);
    if (description)
        formData.append('description', description);
    return {
        url: `/api/public/projects/${projectId}/documents/upload`, method: "POST", requestBody: formData, config: {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
    };
}

const updateDocument = (documentId: string, name: string):ApiContract =>{
    const formData = new FormData();
    formData.append('name', name);
    return {url:`/api/public/documents/${documentId}`, method: 'PUT', requestBody: formData}
}
const getSingleDocument = (documentId: string):ApiContract =>{
    return {url:`/api/public/documents/${documentId}`, method: 'GET'}
}

const addUserToDocument = (documentId: string,usersIds:any[]): ApiContract => {
    return {url: `/api/public/documents/${documentId}/users`, method: 'POST',requestBody: {clientIds: usersIds}}
}

const removeUserFromDocument = (documentId: string,userId:string): ApiContract => {
    return {url: `/api/public/documents/${documentId}/user/${userId}/remove`, method: 'DELETE'}
}
const getAllDocumentUser = (documentId: string): ApiContract => {
    return {url: `/api/admin/document/${documentId}/users`, method: 'GET'}
}


const getDocumentDetails = (documentId: string): ApiContract => {
    return {url: `/api/public/documents/${documentId}`, method: 'GET'}
}

const unPinDocument = (documentId: string): ApiContract => {
    return {url: `/api/public/documents/${documentId}/un-pin`, method: 'POST'}
}

const pinDocument = (documentId: string): ApiContract => {
    return {url: `/api/public/documents/${documentId}/pin`, method: 'POST'}
}
const unArchive = (documentId: string): ApiContract => {
    return {url: `/api/public/documents/${documentId}/un-archive`, method: 'POST'}
}
const getAllDocuments = (projectId: number): ApiContract => {
    return {url: `/api/public/projects/${projectId}/v1/documents`, method: 'GET'}
}
const downloadDocument = (documentId: string): ApiContract => {
    return {
        url: `/api/public/documents/${documentId}/download`, method: 'GET', config: {
            responseType: 'blob'
        }
    }
}
const deleteDocument = (documentId: string): ApiContract => {
    return {url: `/api/public/documents/${documentId}`, method: 'DELETE'}
}
const softDeleteDocument = (documentId: string): ApiContract => {
    return {url: `/api/public/documents/${documentId}/archive`, method: 'DELETE'}
}
export {
    uploadDocument,
    getAllDocumentUser,
    removeUserFromDocument,
    addUserToDocument,
    unPinDocument,
    updateDocument,
    pinDocument,
    unArchive,
    getAllDocuments,
    downloadDocument,
    deleteDocument,
    getSingleDocument,
    softDeleteDocument,
    getDocumentDetails
}