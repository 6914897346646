import {useState} from "react";
import {Spinner} from "../spinner/Spinner";

export const ImageViewer = ({url, fileName, onHide}: {url?: string, fileName?: string, onHide: ()=> void}) => {
    const [loading, setLoading] = useState<boolean>(false);

    return url?<div className={`position-fixed start-0 top-0 vw-100 vh-100 d-flex justify-content-center align-items-center`} style={{backgroundColor: '#000C', zIndex: '9999'}} >
        <div className={"d-flex flex-column"} style={{maxWidth: '75%', maxHeight: '80%', height: '75%', width: '80%'}} onClick={(evt)=> {
            evt.stopPropagation();
        }}>
            <div className={`d-flex justify-content-between pb-2`}>
                <h5></h5>
                <span className={`bi bi-x-lg text-white rounded-circle border d-flex justify-content-center align-items-center cursor-pointer`} style={{width: '28px', height: '28px'}} onClick={()=> onHide()} />
            </div>
            {loading? <Spinner />: null}
            <img src={url} style={{height: '100%', width: '100%', maxHeight: '100%', maxWidth: '100%', objectFit: 'contain'}} alt={fileName} onLoadStart={()=> alert("start")} />
            <p className={`text-center text-white`}>{fileName}</p>
        </div>
    </div>:null
}