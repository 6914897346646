import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Index} from "./pages/Index";
import {DefaultProjectProvider} from "./hooks/DefaultProject";
import { initGA } from './utils/ganalytics';


function App() {
  useEffect(() => {
    initGA(); 
    const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER;

    if (gtmId) {
      TagManager.initialize({ gtmId });
    } else {
      console.error('GTM ID not found in environment variables.');
    }
  }, []);

  return (
    <div className="h-100">
        <DefaultProjectProvider>
            <Index />
        </DefaultProjectProvider>
    </div>
  );
}

export default App;
