import log from "../../assets/images/logo.svg";
import userIcon from "../../assets/icons/user.svg";
import './appHeader.styles.scss';
import {Searchbar} from "../searchbar/Searchbar";
import {useSessionManager} from "../../hooks/UseSessionManager";
import React, {useEffect, useState} from "react";
import {useApiClient} from "../../network/client/UseApiClient";
import {UserRole, UsersModel} from "../../models/UsersModel";
import {AccountDetailApi} from "../../services/AccountService";
import {NetworkState} from "../../network/dto/ApiContract";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {getUserRoleLabel} from "../../utils/AppUtils";
import {NotificationModal} from "../notifications/NotificationModal";

export const AppHeader = () => {
    const {session, chatUser, setChatUser, createSession, setUser, clearSession} = useSessionManager();
    const [user, userNetworkState, error, userData] = useApiClient<UsersModel>();
    const [show, setShow] = useState<boolean>(false);
    const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false)
    const navigate = useNavigate();

    /*useEffect(() => {
        if (session && chatUser)
            userData(LoginUser(session?.user?.id||'')).then((res:any)=>{
                setUser(res.fullName)
                console.log(res.fullName)
         })
    }, [session]);*/

    useEffect(() => {
        userData(AccountDetailApi())
    }, []);

    useEffect(() => {
        if (userNetworkState === NetworkState.Loaded) {
            setChatUser({
                quivrId: user?.quivrId || '',
                user_name: user?.fullname || '',
                brainId: user?.organization?.brainId || '',
                role: user?.role || UserRole.Member
            });
        }
    }, [userNetworkState])


    return <header className={`border-bottom bg-white sticky-top`}>
        <div className={`appHeader`}>
            <div className={`col align-self-center`}>
                <img src={log} className={`logo`} alt={`_logo`}/>
            </div>
            <Searchbar/>

            {/*<div className={`align-self-center d-flex menuBar col justify-content-end`}>*/}
            {/*    <div className={`me-3`} onClick={()=> {*/}
            {/*        clearSession();*/}
            {/*    }}>*/}
            {/*        <img src={userIcon} className={`icon me-1`} />*/}
            {/*        <small>Hello, {user?.fullname?.split(" ")[0]||'Unknown'} | {user?.organization?.name}</small>*/}
            {/*        /!*<small>Hello, {chatUser?.user_name?.split(" ")[0]||'Unknown'}</small>*!/*/}
            {/*    </div>*/}
            {/*    <div className={`d-none`}>*/}
            {/*        <img src={cartIcon} className={`icon me-1`} />*/}
            {/*        <small>My Cart</small>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <div className="d-flex align-items-start d-flex menuBar col justify-content-end">
                <div className={`align-self-center d-flex align-items-center`}>
                    <div className={`me-2`}><span className={`bi bi-bell text-dark fs-4 cursor-pointer`} onClick={()=> {
                        setShowNotificationModal(true)
                    }} /></div>
                    <div className={`me-4 small`}>{user?.organization?.name}</div>
                </div>

                <Dropdown className={'align-self-center'}>
                    <Dropdown.Toggle id="dropdown-basic"
                                     className="p-0 bg-transparent border-0 d-flex text-black dropDown_toggle align-items-center">
                        <img src={userIcon} className={`icon me-1`} />
                        <small>Hello, {user?.fullname?.split(" ")[0] || 'Unknown'} ({getUserRoleLabel(user?.role || UserRole.Unknown)})</small>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropDown_menu bg-white w-100 shadow border-white">
                        <Dropdown.Item className={'border-bottom'} onClick={() => navigate(`/settings/users`)
                        }><i className="bi bi-people fs-6 me-2"></i>Admin Settings</Dropdown.Item>
                        {/*<Dropdown.Item className={'border-bottom'} onClick={() => navigate(`/adminSettings`)*/}
                        {/*}><i className="bi bi-wrench fs-6 me-2"></i>Admin Settings</Dropdown.Item>*/}

                        <Dropdown.Item onClick={(e) => {
                            clearSession();
                            navigate('/login');
                        }}><i className="bi bi-box-arrow-right fs-6 me-2"></i>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <NotificationModal show={showNotificationModal} onHide={()=> {
                setShowNotificationModal(false)
            }} />
        </div>

    </header>
}