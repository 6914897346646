import sortOrder from "../../../../../../assets/icons/sort_order.svg";
import React, {Fragment, useEffect, useState} from "react";
import '../projectClients/ProjectClientListCard/projectClientListCard.scss'
import {useApiClient} from "../../../../../../network/client/UseApiClient";
import {useParams} from "react-router";
import {Client} from "../../../../../../models/Client";
import {NetworkState} from "../../../../../../network/dto/ApiContract";
import {Spinner} from "../../../../../../components/spinner/Spinner";
import {AddNewTeamMember} from "./addNewTeamMember/AddNewTeamMember";
import {EmptyState} from "../../../../../../components/emptyState/EmptyState";
import {ProjectTeamMemberListCard} from "./ProjectTeamMemberListCard/ProjectTeamMemberListCard";
import {GetAllProjectTeamMembers} from "../../../../../../services/TeamService";


export const ProjectTeamMembers = () => {
    const [teamMembers, teamMembersNetworkState, error, fetchTeamMembers] = useApiClient<Client[]>();
    const params = useParams<{ projectId: string }>();
    const [activeMember, setActiveMember] = useState<Client>();
    const [showModal, setShowModal] = useState<boolean>(false);


    useEffect(() => {
        fetchTeamMembers(GetAllProjectTeamMembers(+(params?.projectId || 1)))

    }, [params?.projectId]);

    return <div className={'mt-1'}>
        <div className={``}>
            <div className={`d-flex`}>
                <button className={`btn btn-primary d-flex`} onClick={() => setShowModal(true)}>
                    <img src={''} className={'uploadIcon me-1'}/>
                    + New Member
                </button>
                <div className={`ms-3 d-flex align-items-center`} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <img src={sortOrder} className={'me-2'}/>
                    <small>Sort by: Most Recent</small>
                </div>
            </div>
            {teamMembersNetworkState === NetworkState.Loading ? <div className={`w-100 d-flex justify-content-center`}>
                    <Spinner/>
                </div> :
                <div className={'clients mt-4'}>
                    {teamMembers?.length ? <Fragment>
                        {teamMembers?.map((data: Client) => <ProjectTeamMemberListCard key={data.id}
                                                                                       projectId={params.projectId || ''}
                                                                                       data={data} onDelete={() => {
                            fetchTeamMembers(GetAllProjectTeamMembers(+(params?.projectId || 1)))
                        }} isActive={activeMember?.id === data.id} onClick={() => {
                            if (activeMember?.id === data.id) {
                                setActiveMember(undefined)
                            } else
                                setActiveMember(data)
                        }} onSuccess={() => {
                            fetchTeamMembers(GetAllProjectTeamMembers(+(params?.projectId || 1)))

                        }
                        }/>)}</Fragment> : <div className={'container bg-white h-100'}><EmptyState/></div>}
                </div>}
        </div>
        <AddNewTeamMember show={showModal} onCancel={() => setShowModal(false)} onSuccess={() => {
            setShowModal(false);
            fetchTeamMembers(GetAllProjectTeamMembers(+(params?.projectId || 1)))
        }}/>
    </div>
}