import {useEffect, useRef, useState} from "react";
import {initViewer, loadModel} from "../../utils/autodesk-utils";

export const ForgeViewer = (props: {docDetails: any, highlight?: number}) => {
    const viewerContainer = useRef<HTMLDivElement>(null);
    const [viewer, setViewer] = useState<any>();
    const highlightBtn = useRef<HTMLButtonElement>(null)

    useEffect(()=> {
        init()
    }, []);

    const init = async () => {
        const vw: any = await initViewer(viewerContainer.current!!, {extensions: ['Autodesk.DocumentBrowser']});
        console.log('viewer', vw);
        setViewer(vw);
        await loadModel(vw, props.docDetails.urn, onDocLoaded);
        vw.addEventListener(window.Autodesk.Viewing.SELECTION_CHANGED_EVENT , function (evt: any) {
            console.log("Evt", evt)
            vw.fitToView( vw.getSelection() );
            // focus("");
        });
        setTimeout(()=> {
            highlightBtn.current?.click()
        }, 3000);
    }

    //Currently not in use. Also, not working for the first time when drawing is loaded
    const onDocLoaded = (doc: any) => {
        if (viewer && props.highlight) {

        }
    }

    const focus = (guid: number) => {
        if (viewer) {
            viewer.select([guid===0?2654:guid], viewer.model, window.Autodesk.Viewing.SelectionMode.REGULAR);
            console.info("selection done")
        } else {
            console.error("Viewer not initiated")
        }
    }
    return <div className={``}>
        <button ref={highlightBtn} className={`btn btn-sm position-absolute top-0 start-0 invisible`} onClick={()=> focus(props.highlight!!)}>Highlight</button>
        <div ref={viewerContainer}></div>
    </div>;
}