import './SyncModal.scss';
import React, {FormEvent, useEffect} from 'react';
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import {useToast} from "../../../../../../../components/toast/ToastManager";
import {Button} from "../../../../../../../components/button/Button";

export const SyncModal = (props: {

    show: boolean,
    onCancel: (shouldRefresh?: boolean) => void,
    onSuccess: (shouldRefresh?: boolean) => void,
    defaultValue?: string
}) => {
    const toast = useToast();

    useEffect(() => {

    }, [])

    const submitForm = (event: FormEvent) => {
        event.preventDefault();
    }

    return <React.Fragment>
        <Modal show={props.show} onHide={props.onCancel} centered style={{}} className={'modal documentModal'}>
            <Modal.Header closeButton className="border-0 pb-0">
                {/*<h6 className={'fw-bolder'}>{"Upload New Document"}</h6>*/}
            </Modal.Header>
            <ModalBody className="modal-content rounded-0 border-0">
                <form id={`form_file_upload`} className={'flex-fill overflow-y-auto'} onSubmit={submitForm}>
                </form>
            </ModalBody>
            <ModalFooter className={'d-flex justify-content-end border-0'}>
                <button type={'button'} className={'btn btn-light py-0 px-3 rounded-0 small fw-bold'}
                        onClick={() => props.onCancel(false)}>Cancel
                </button>
                <Button type={'submit'}  form={`form_file_upload`} loaderText={`...uploading`} className={'btn btn-primary py-0 px-3 rounded-0 small'} onClick={() => props.onSuccess}>Open</Button>
            </ModalFooter>
        </Modal>
    </React.Fragment>
}