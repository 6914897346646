import './settingModal.scss';
import React, {FormEvent, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import {useApiClient} from "../../../../../../../network/client/UseApiClient";
import {useInputHandler} from "../../../../../../../hooks/UseInputHandler";
import {useParams} from "react-router";
import {NetworkState} from "../../../../../../../network/dto/ApiContract";
import {useToast} from "../../../../../../../components/toast/ToastManager";
import {v4} from "uuid";
import {Button} from "../../../../../../../components/button/Button";
import {Integration} from "../../../../../../../models/Integration";
import {AddIntegration, IntegrationDetail, UpdateIntegration} from "../../../../../../../services/IntegrationService";
import {Project} from "../../../../../../../models/Project";
import {getProjects} from "../../../../../../../services/ProjectService";
import {Option} from "../../../../../../../components/selectDropdown/SelectDropDown";
import Select from "react-dropdown-select";

export const IntegrationFormModal = (props: {
    show: boolean,
    onCancel: (shouldRefresh?: boolean) => void,
    onSuccess: (shouldRefresh?: boolean) => void,
    defaultValue?: string
}) => {
    const {payload, handleChange, setData ,setValue} = useInputHandler<any>({});
    const [integration, integrationNetworkState, error, postPutIntegration] = useApiClient<Integration[]>();
    const params = useParams<{ projectId: string }>();
    const toast = useToast();
    const [integrationData, integrationDataNetworkState, dataError, getIntegrationData] = useApiClient<Integration>();
    const [active, setActive] = useState<boolean>(false);
    const [projects,projectNetworkState,projectError,fetchProjects]=useApiClient<Project[]>();
    const[selectedProject,setSelectedProject]=useState<any>('');
    const[listProject,setListProject]=useState<any>([]);

    const handleCheckboxChange = () => {
        setActive((prevActive) => !prevActive);
    };

    useEffect(() => {
        if (integrationNetworkState === NetworkState.Loaded) {
            props.onSuccess(true);
            // toast.show({id: v4(), title: "Added Successfully!"})
        }
    }, [integrationNetworkState])

    useEffect(() => {
        if (props?.defaultValue) {
            getIntegrationData(IntegrationDetail(props.defaultValue)).then((res) => {
                setData(res)
                setSelectedProject({label: res.project.name, value: res.project.id})
                // setValue('projectId', [{value: res.project.id}]);
            })
        }

    }, [props?.defaultValue]);


    useEffect(() => {
        // fetchProjects(getProjects());
        getAllProjectsList();
    }, []);




    const getAllProjectsList=()=>{
        fetchProjects(getProjects()).then((res)=>{
            const _sub: any = []
            res.map((re: any) => {
                _sub.push({
                    label: re.name,
                    value: re.id
                })
            })
            setListProject(_sub);
        })
    }

    const submitForm = (event: FormEvent) => {
        // event.preventDefault();
        // addIntegration();
        event.preventDefault();
        if (props?.defaultValue) {
            postPutIntegration(UpdateIntegration(props?.defaultValue, payload)).then(res => {
                props.onSuccess(true);
                toast.show({id: v4(), title: "Updated Successfully", duration: 2000})
            }).catch(err=>{
                if(err.response.status !==403){
                    toast.show({id: v4(), title: err.response.message, duration: 2000, isSuccess: false});
                }
            })
        } else {
            postPutIntegration(AddIntegration(payload)).then(res => {
                props.onSuccess(true);
                toast.show({id: v4(), title: "Integration Added Successfully", duration: 2000})
            }).catch(err=>{
                    if(err.response.status !==403){
                        toast.show({id: v4(), title: err.response.message, duration: 2000, isSuccess: false});
                    }
                })
        }
    }

    const onProjectChange=(options: Option[])=> {
        // setValue('usersId', options.map(option=> option.value));
        if(options) {
            setSelectedProject(options.map(option=> option.value))
            setValue('projectId', options.map(option=> option.value));
        }else setSelectedProject([])
    }

    const handleSelect = (value: any) => {
        const id = value[0].value;
        // payload({projectId:id});
        setValue('projectId', id);
        console.log("projectId", id)
    }
    return <React.Fragment>
        <Modal show={props.show} onHide={props.onCancel} centered style={{}} className={'modal documentModal'}>
            <Modal.Header closeButton className="border-0 pb-0">
                <h6 className={'fw-bolder'}>{props?.defaultValue ? "Update Integration" : "Add New Integration"}</h6>
            </Modal.Header>
            <ModalBody className="modal-content rounded-0 border-0 overflow-visible">
                <form id={`form_file_upload`} className={'flex-fill '} onSubmit={submitForm}>
                    <div className="form-group mt-2">
                        <label className={'small text-secondary'}> Name <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control rounded-0" id="exampleInputName"
                               aria-describedby="nameHelp"
                               onChange={handleChange} value={payload.name || ''} name="name" required/>

                    </div>
                    <div className="form-group mt-2">
                        <label className={'small text-secondary'}><b>Description</b> (optional)</label>
                        <textarea rows={2} className="form-control rounded-0" id="exampleInputName"
                                  aria-describedby="nameHelp"
                                  onChange={handleChange} value={payload.description || ''} name="description"/>
                    </div>
                    <div className="form-group mt-2">
                        <label className={'small text-secondary'}>Select Project <span className='text-danger'>*</span></label>
                        <Select className={`w-100`} placeholder={"select"}
                                values={[selectedProject]} options={listProject || []} onChange={(project)=>handleSelect(project)} required/>
                        {/*<SelectDropDown values={selectedProject}  options={projects?.map((project:any)=> ({label: project?.name, value: project?.id}))} onSelect={()=>handleSelect(selectedProject.id)} placeholders={'select'}/>*/}
                        {/*<SelectDropDown values={selectedProject}  options={projects?.map((project:any)=> ({label: project?.name, value: project?.id}))} onSelect={onMemberChange} placeholders={'select'}/>*/}
                    </div>
                    <div className="form-group mt-2">
                        <label className={'small text-secondary'}> Type (Integration Type) <span className='text-danger'>*</span></label>
                        <select className="form-control rounded-0" id="exampleInputName" aria-describedby="nameHelp"
                                onChange={handleChange} value={payload.integrationType || ''} name="integrationType" required>
                            <option value={``} disabled>Select Type</option>
                            <option value={`EgnyteZapier`}>Egnyte Zapier</option>
                            <option value={`GoogleZapier`}>Google Zapier</option>
                            <option value={`SharepointZapier`}>Sharepoint Zapier</option>
                        </select>
                    </div>
                    {payload.integrationType === 'EgnyteZapier' ? <fieldset className={`p-2 mt-2`}>
                        <label className={`text-decoration-underline`}>Egnyte Config</label>
                        <div className="form-group mt-2">
                            <label className={'small text-secondary'}> Domain </label>
                            <input type="text" className="form-control rounded-0" id="exampleInputName"
                                   aria-describedby="nameHelp"
                                   onChange={handleChange} value={payload.EgnyteZapier_domain || ''} name="EgnyteZapier_domain"
                                   placeholder={`eg. https://example.egnyte.com`}/>

                        </div>
                    </fieldset> : payload.integrationType === 'GoogleZapier' ? null : null}



                    {/*<div className={'form-group mt-2'}>*/}
                    {/*    /!*<CustomSwitcher active={payload?.active} onChange={()=>!payload.active} activeLabel={'Active'}/>*!/*/}

                    {/*    <label>*/}
                    {/*        <input type="checkbox" checked={payload?.active || false} value={payload?.active} onChange={()=>!payload?.active}/>*/}
                    {/*        Is Active*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    {props?.defaultValue ? '' :
                        <div className="form-group mt-2">
                        <label className={'small text-secondary'}> Active </label>
                        <select className="form-control rounded-0" id="exampleInputName" aria-describedby="nameHelp"
                                onChange={handleChange} value={payload?.active || false} name="active">
                            <option value={``} disabled>Select Status</option>
                            <option value={'true'}>True</option>
                            <option value={'false'}>False</option>
                        </select>
                        </div>

                        // <Form>
                        //     <Form.Check
                        //         type="switch"
                        //         id="custom-switch"
                        //         checked={active}
                        //         label="Publish"
                        //         onClick={(e:any) => !active}
                        //     />
                        // </Form>


                    }
                </form>
            </ModalBody>
            <ModalFooter className={'d-flex justify-content-end border-0'}>
                <Button type={'button'} className={'btn btn-outline-primary py-0 px-3 small fw-bold'}
                        onClick={() => props.onCancel(false)}>Cancel
                </Button>
                <Button type={'submit'} loading={integrationNetworkState === NetworkState.Loading}
                        form={`form_file_upload`} loaderText={`...saving`}
                        className={'btn btn-primary py-0 px-3 small'}>{props?.defaultValue ? "Update" : "Add"}</Button>
            </ModalFooter>
        </Modal>
    </React.Fragment>
}