import {matchPath, Route, Routes, useLocation} from "react-router";
import {McDashboardPage} from "../dashboard/McDashboardPage";
import {McManagePage} from "../manage/McManagePage";
import {McProcurePage} from "../procure/McProcurePage";
import {McMarketplacePage} from "../marketplace/McMarketplacePage";
import './manageConsole.scss';
import {AppHeader} from "../../../components/header/AppHeader";
import React, {useContext, useEffect, useState} from "react";
import {TabMenuItem} from "../../../components/tab/TabMenuItem";
import dashboardIcon from "../../../assets/icons/dashboard.png";
import manageIcon from "../../../assets/icons/manage.png";
import {ProjectDetailPage} from "../manage/project/details/ProjectDetailPage";
import Users from "../../manageUsers/Users";
import {Modal, OverlayTrigger, Popover} from "react-bootstrap";
import {AddNewProject} from "../manage/project/addNewProject/AddNewProject";
import {ChatModal} from "../../chatroom/ChatModal";
import AdminSettings from "../../adminSettings/AdminSettings";
import {SetDefaultProjectGuideModal} from "../../../components/guide/SetDefaultProjectGuideModal";
import {DefaultProjectContext} from "../../../hooks/DefaultProject";

export const ManagementConsolePage = () => {
    const path = useLocation();
    const [activeMenu, setActiveMenu] = useState<string>("");
    const [showChat, setShowChat] = useState<boolean>(false);
    const dps = useContext(DefaultProjectContext);
    const [showSetDefaultProjectGuide, setShowSetDefaultProjectGuide] = useState<boolean>(false)

    useEffect(()=> {
        if (path) {
            setActiveMenu(path.pathname)
            setShowChat(false)
        }
    }, [path])

    return <div className={`d-flex flex-column h-100`}>
        <AppHeader/>
        <div className={`position-relative d-flex justify-content-center shadow-sm bg-white z-1`}>
            <nav className="nav flex-column flex-sm-row nav-tabs my-3">
                <TabMenuItem icon={dashboardIcon} label={'Dashboard'} pathname={'/'}
                             isActive={!!matchPath("/", path.pathname)} />
                <TabMenuItem icon={manageIcon} label={'Manage'} pathname={'/manage'}
                             isActive={!!matchPath("/manage/*", path.pathname)}/>
                {/*<TabMenuItem icon={procureIcon} label={'Procure'} pathname={'/procure'}
                             isActive={!!matchPath("/procure*", path.pathname)}/>
                <TabMenuItem icon={marketplaceIcon} label={'Marketplace'} pathname={'/marketplace'}
                             isActive={!!matchPath("/marketplace*", path.pathname)}/>*/}
            </nav>
            <div className={`btnChat`} onClick={()=> {
                if (dps.project || dps.defaultProject)
                    setShowChat(prev => !prev);
                else
                    setShowSetDefaultProjectGuide(true)
            }}>
                {/*<img src={chatButtonBg}/>*/}
                <label className={`cursor-pointer`}>{showChat?<span className={`bi bi-x-lg`} />:'Chat'}</label>
            </div>
        </div>

        <div className={`flex-fill bg-body`}>
            <Routes>
                <Route path={'/'} element={<McDashboardPage/>}/>
                <Route path={'/manage'} element={<McManagePage/>}/>
                <Route path={'/manage/project/addProject'} element={<AddNewProject/>}/>
                <Route path={'/manage/project/edit-project/:projectId'} element={<AddNewProject/>}/>
                <Route path={'/manage/project/:projectId/*'} element={<ProjectDetailPage/>}/>
                <Route path={'/procure'} element={<McProcurePage/>}/>
                <Route path={'/marketplace'} element={<McMarketplacePage/>}/>
                <Route path={'/users'} element={<Users/>}/>
                <Route path={'/settings/*'} element={<AdminSettings/>}/>
            </Routes>
        </div>
        <Modal className={`overflow-hidden border-0 shadow-sm modal modal-xl vh-100 `} show={showChat} onHide={()=> {setShowChat(false)}}><ChatModal onClose={()=> setShowChat(false)} /></Modal>
        <SetDefaultProjectGuideModal show={showSetDefaultProjectGuide} onHide={()=> setShowSetDefaultProjectGuide(false)} />
    </div>;
}