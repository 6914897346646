import {UsersModel} from "../../models/UsersModel";
import {useApiClient} from "../../network/client/UseApiClient";
import {Project} from "../../models/Project";
import React, {ChangeEvent, FormEvent, Fragment, useEffect, useState} from "react";
import {getProjects} from "../../services/ProjectService";
import {Button} from "../button/Button";
import {NetworkState} from "../../network/dto/ApiContract";
import {v4} from "uuid";
import {useToast} from "../toast/ToastManager";
import {AddPermissions, UpdatePermissions} from "../../services/ProjectPermissions";
import {useInputHandler} from "../../hooks/UseInputHandler";
import PermissionConfirmationModal from "../PermissionConfirmationDialog/PermissionConfirmationModal";

export const AddProjectPermission = (props: {
    user: UsersModel,
    onCancel: (shouldRefresh?: boolean) => void,
    onSuccess: (shouldRefresh?: boolean) => void,
    show: boolean,
    defaultValue?: string,
    permissionIdx?: number
}) => {
    const [projects, projectNetworkState, error, fetchProjects] = useApiClient<Project[]>();
    const [selectedProject, setSelectedProject] = useState<any>('')
    const [projectList, setProjectList] = useState<Project[]>([]);
    const [showPermissionConfirmation, setShowPermissionConfirmation] = useState<boolean>(false);
    const [showAdminPermissionConfirmation, setShowAdminPermissionConfirmation] = useState<boolean>(false);

    const [permission, permissionNetworkState, permissionError, addUpdatePermissions] = useApiClient<any>();
    const toast = useToast();
    const {payload, handleChange, setValue, setData} = useInputHandler<any>({});

    useEffect(() => {
        fetchProjects(getProjects()).then((res) => {
            setProjectList(res);
            // setProjectList(res);
        });
    }, [])

    useEffect(()=> {
        if (props.permissionIdx && props.permissionIdx >= 0) {
            const permission = props.user.permissions![props.permissionIdx-1];
            setValue("projectId", permission.project.id);
            setValue("role", permission.role);
        }
    }, [props.permissionIdx])

    const submitForm = (event: FormEvent) => {
        event.preventDefault();
        if (payload.role === 'Super_Admin') {
            setShowPermissionConfirmation(true);
        }else if (payload.role === 'Admin') {
            setShowAdminPermissionConfirmation(true);
        }
        else  {
            submit()
        }
    }

    const submit = () => {
        if(props.defaultValue){
                addUpdatePermissions(UpdatePermissions(props.defaultValue,payload.role,payload.userGroup,payload.user.id || '')).then(res =>{
                    props.onSuccess(true);
                    toast.show({id: v4(), title: 'Updated Successfully',duration: 4000, isSuccess: false})
                }).catch((err)=> {
                    if (err.response.status !== 403) {
                        toast.show({id: v4(), title: err.response.data.message, duration: 4000, isSuccess: false})

                    }
                })
        }
        addUpdatePermissions(AddPermissions(payload.projectId, payload.role, payload.userGroup,props.user.id||'')).then(res => {
            props.onSuccess(true);
            toast.show({id: v4(), title: "Saved Successfully", duration: 3000})
        }).catch(err => {
            if (err.response.status !== 403) {
                // const errorMessage = err.response.data.details[0];
                toast.show({id: v4(), title: err.response.data.message, duration: 4000, isSuccess: false});
            }
        })
    }


    const handleSelect = (evt: ChangeEvent<HTMLSelectElement>) => {
        const id = evt.target.value;
         setSelectedProject(id)
    }

    return <Fragment>
        <div className={`d-flex justify-content-between p-3 pb-0`}>
            <h6 className={`mb-0`}><span className={`text-secondary`}>Add Project Permission for </span> <span
                className={`text-light`}>•</span> <span>{props.user?.fullname}</span></h6>
            <span className={`bi bi-x-lg p-0 cursor-pointer`} onClick={() => {
                props.onCancel();
            }}/>
        </div>
        <hr />
        <div className={`p-3 pt-0`}>
            <form id={`form_member`} className={'flex-fill overflow-y-auto mb-3'} onSubmit={(event) => submitForm(event)}>
                <div className="form-group mt-2">
                    <label htmlFor="exampleInputSubjects ms-2" className={`ps-2`}>Role</label>
                    <select className={`w-100 form-control`} name={"role"} value={payload.role} onChange={handleChange}>
                        <option value={""}>SELECT A ROLE</option>
                        <option value={"Super_Admin"}>Super Admin</option>
                        <option value={"Admin"}>Admin</option>
                        <option value={"Member"}>Member</option>
                    </select>
                </div>
                {
                    payload.role  === 'Super_Admin' ? '' :
                        <div className="form-group mt-2">
                            <label htmlFor="exampleInputSubjects" className={`ps-2`}>Project</label>
                            <select className={`w-100 form-control`}
                                    name={"projectId"}
                                    value={payload.projectId}
                                    onChange={handleChange}>
                                <option value={""}>SELECT A PROJECT</option>
                                {projects?.map(project=> <option value={project.id}>{project.name}</option>)}
                            </select></div>
                }


                {
                    payload.role === 'Member' ? <div className="form-group mt-2">
                    <label htmlFor="exampleInputSubjects ms-2" className={`ps-2`}>Group</label>
                    <select className={`w-100 form-control`} name={"userGroup"} value={payload.userGroup}
                            onChange={handleChange}>
                        <option value={""}>SELECT A GROUP</option>
                        <option value={"TEAM_MEMBER"}>Team Member</option>
                        <option value={"PARTNER"}>Partner</option>
                        <option value={"OWNER"}>Owner</option>
                    </select>
                </div> : ''}
            </form>
            <div>
                <Button type={'button'} className={'btn btn-outline-primary py-0 px-3 small fw-bold me-2'}
                        onClick={() => props.onCancel(false)}>Cancel
                </Button>
                <Button type={'submit'} loading={permissionNetworkState === NetworkState.Loading} form={`form_member`}
                        loaderText={`...saving`}
                        className={'btn btn-primary py-0 px-3  small'}>{props?.permissionIdx ? 'Update' : "Add"}</Button>
            </div>
        </div>
        <PermissionConfirmationModal title={`Permissions`} message={`Are you sure? By providing super admin permissions to this user, This user will be become super user to all the projects in Org.`} onSubmit={()=> {
        setShowPermissionConfirmation(false);
        submit()}
        } onCancel={()=> {setShowPermissionConfirmation(false)}} show={showPermissionConfirmation} btnText={'Grant'} />

        <PermissionConfirmationModal title={`Permissions`} message={`Are you sure? By providing admin permissions to this user, This user will be able to manage this project.`} onSubmit={()=> {
            setShowAdminPermissionConfirmation(false);
            submit()}
        } onCancel={()=> {setShowAdminPermissionConfirmation(false)}} show={showAdminPermissionConfirmation} btnText={'Grant'} />

    </Fragment>
}