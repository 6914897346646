import React, {Fragment, useState} from "react";
import './teamImages.css';
import ImageUpload from "../../components/upload/ImageUpload";
import TeamImageUpload from "../../components/upload/TeamImageUpload/TeamImageUpload";

export const TeamImages = (props: {data: any[],teamMemberId:string,projectId:string,onSuccess:()=>void}) => {
    const [data, setData] = useState<any[]>(props.data);
    const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
    const [isUploaded, setIsUploading] = useState<boolean[]>([]);

    const handleUploadSuccess = () => {
        // const updatedData = [...data, { url: imageUrl }];
        // setData(updatedData);
        setIsUploading([...isUploaded, true]);
        props.onSuccess()
    };
    const handleUploadFailed = () => {
        // const updatedData = [...data, { url: imageUrl }];
        // setData(updatedData);
        setIsUploading([...isUploaded, false]);
        // props.onSuccess()
    };

    const handleFileChange = (file: File | null) => {
        if (file) {
            setUploadingFiles([...uploadingFiles, file]);
            setIsUploading([...isUploaded, false]);
        }
        props.onSuccess()
    };


    return (
        <Fragment>
            {data.length > 0 ? (
                <div className={'row row-cols-6 g-3'}>
                    {/* Image upload component */}
                    <div style={{ height: '150px', width: '200px' }}>
                        <ImageUpload clientId={props.teamMemberId} projectId={props.projectId} onDone={handleUploadSuccess} onError={handleUploadFailed} onFileChange={handleFileChange}/>
                    </div>
                    {/* Display images from the data array */}
                    {data?.map((image, index) => (
                        <div key={index} className={'col'} style={{ height: '150px', width: '200px', overflow: "hidden" }}>
                            <img style={{ height: '100%', width: '100%', objectFit: "cover" }} src={`${image?.url}`} className="img-fluid" alt={`Image ${index}`} loading={'lazy'}/>
                        </div>
                    ))}

                    {/* Display the uploading images */}
                    {uploadingFiles.map((file, index) => (
                        <div key={`uploading-${index}`} className={'col'} style={{ height: '150px', width: '200px', overflow: 'hidden' }}>
                            <div className="uploading-image">
                                <div className="image-container">
                                    <img
                                        src={file ? URL.createObjectURL(file) : ''}
                                        alt='Uploading...'
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                                    />
                                    {isUploaded[index] ? '' : (
                                        <div className="spinner-border spinnerAlignment" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (

                <div className={ uploadingFiles.length > 0 ? 'row row-cols-6 g-3' : ""}>
                    <div className={ uploadingFiles.length > 0 ? 'uploadImage' : ""}>
                        <TeamImageUpload memberId={props.teamMemberId} projectId={props.projectId} onDone={handleUploadSuccess} onFileChange={handleFileChange}/>
                    </div>

                    {/* Display the uploading images when there are no images */}
                    {uploadingFiles.map((file, index) => (
                        <div key={`uploading-${index}`} className={'col'} style={{ height: '150px', width: '200px', overflow: 'hidden' }}>
                            <div className="uploading-image">
                                <div className="image-container">
                                    <img
                                        src={file ? URL.createObjectURL(file) : ''}
                                        alt='Uploading...'
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                                    />
                                    {isUploaded[index] ? '' : (
                                        <div className="spinner-border spinnerAlignment" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Fragment>
    );
};