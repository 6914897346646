import {Project} from "../../models/Project";
import {UserGroup, UserRole, UsersModel} from "../../models/UsersModel";
import React, {Fragment, useEffect, useState} from "react";
import {AddProjectPermission} from "./AddProjectPermission";
import {useApiClient} from "../../network/client/UseApiClient";
import {DeletePermissions} from "../../services/ProjectPermissions";
import ConfirmationModal from "../confirmationDialog/ConfirmationModal";
import {getSingleUserApiContract} from "../../services/UserService";
import {useToast} from "../toast/ToastManager";
import {v4} from "uuid";
import {EmptyState} from "../emptyState/EmptyState";
import PermissionConfirmationModalSuperAdmin
    from "../PermissionConfirmationDialogSuperAdmin/PermissionConfirmationModalSuperAdmin";

export const UserProjects = (props: { userName?: UsersModel, projects?: { role: UserRole, project: Project }[], onClose: () => void, onShow: () => void, onChange: ()=> void}) => {
    const [showAddPermissionModal, setShowAddPermissionModal] = useState<boolean>(false);
    const [user, userNetworkState, userError, fetchUser] = useApiClient<UsersModel>();
    const [activePermission, setActivePermission] = useState<number>();
    const toast = useToast();
    const [showPermissionModal, setShowPermissionModal] = useState<boolean>(false);

    useEffect(()=> {
        fetchUser(getSingleUserApiContract(props.userName?.id||'')).then(()=>{

        }).catch((err)=>{
            if(err.response.status !== 403){
                toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})
            }
        })
    }, [])

    const handleChange = () => {
        fetchUser(getSingleUserApiContract(props.userName?.id||'')).then(()=>{

        })
            .catch((err)=>{
            if(err.response.status !== 403){
                toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})
            }
        })
        setActivePermission(undefined)
        props.onChange();
    }

    const addPermissionClick=()=>{
        if ( props.userName?.role === "Super_Admin"){
            setShowPermissionModal(true);
        }else{
            setShowAddPermissionModal(true);
        }
    }

    return <div>
        {
            (showAddPermissionModal && user) ?
                <AddProjectPermission user={user} permissionIdx={(activePermission||-1)+1} onCancel={() => {setShowAddPermissionModal(false); setActivePermission(undefined)}}
                                      show={showAddPermissionModal}
                                      onSuccess={() => { setShowAddPermissionModal(false); handleChange()}}/> : <Fragment>
                    <div className={`d-flex justify-content-between p-3 pb-0`}>
                        <h6 className={`mb-0`}><span className={`text-secondary`}>Project Permissions </span> <span
                            className={`text-light`}>•</span> <span>{user?.fullname}</span></h6>
                        <span className={`bi bi-x-lg p-0 cursor-pointer`} onClick={() => {
                            props.onClose()
                        }}/>
                    </div>
                    <hr/>
                    <div className={`p-3 pt-0`}>
                        <button data-dismiss="modal" type={`button`} className={`btn btn-sm btn-primary mb-2`}
                                onClick={() => {
                                    addPermissionClick();
                                }}>Add Permission
                        </button>
                        {user?.permissions?.length ? <table className={`table`}>
                            <thead>
                            <tr className={`small`}>
                                <th/>
                                <th>Project</th>
                                {/*<th>Project ({user?.permissions?.length})</th>*/}
                                <th>Role</th>
                                <th>Group</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {user?.permissions?.map((project, idx) => <UserProjectInfo idx={idx} role={project.role} group={project.userGroup}
                                                                                       project={project.project}
                                                                                       user={props?.userName}
                                                                                       onChange={() => {
                                                                                           handleChange()
                                                                                       }
                                                                                       } onEdit={() => {
                                setActivePermission(idx);
                                setShowAddPermissionModal(true)
                            }}/>)}
                            </tbody>
                        </table>:  <div className={'container bg-white h-100'} style={{height:'100%'}}> <EmptyState/> </div>}
                    </div>
                    <PermissionConfirmationModalSuperAdmin title={`Permissions`}
                                                           message={`This user is already a super_admin`} onSubmit={() => {
                        setShowPermissionModal(false);
                    }
                    } onCancel={() => {
                        setShowPermissionModal(false)
                    }} show={showPermissionModal} btnText={'Grant'}/>
                </Fragment>
        }
    </div>;
}

export const UserProjectInfo = (props: { idx: number, role: UserRole, group: UserGroup, project: Project , user?: UsersModel, onChange:()=> void, onEdit: ()=> void }) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [permission, permissionNetworkState, error, removeProjectPermission] = useApiClient<Project>();
    const toast = useToast();


    const deleteProjectPermissions = (projectId:string,userId?:string) => {
        removeProjectPermission(DeletePermissions(projectId,userId || '')).then( res=> {
                props.onChange();
                toast.show({id: v4(), title: "Deleted Successfully", duration: 2000})
            }).catch(err=>{
                if(err.response.status !== 403){
                    toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})
                }
            })
        }
    return <Fragment>
        <tr className={`small text-start`}>
            <td>{props.idx + 1}.</td>
            <td>{props.role === 'Super_Admin' ? 'All Projects' : props.project.name}</td>
            <td>{props.role}</td>
            <td>{props.group || '-'}</td>
            <td style={{width: '80px'}}>
                {/*<span className={`bi bi-pencil text-success cursor-pointer`} onClick={()=>props.onEdit()}/> */}
                <span className={`bi bi-trash text-danger cursor-pointer ms-3`} onClick={()=>setShowConfirmationDialog(true)}/></td>
        </tr>
        <ConfirmationModal title={'Delete Permission'} message={'Are you sure you want to delete this Permission?'} onSubmit={()=> {
            deleteProjectPermissions(props?.project?.id || '',props?.user?.id || '');
            setShowConfirmationDialog(false)
        }} onCancel={()=>setShowConfirmationDialog(false)} show={showConfirmationDialog} btnText={'Delete'}/>

    </Fragment>
}