import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "../common/AppConstants";

// Initialize Google Analytics
export const initGA = () => {
  console.log("Initializing GA Metric Library with the mesaurement ID " + GOOGLE_ANALYTICS_MEASUREMENT_ID)
  ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID ?? "");
};

// Log a page view
export const logPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Log custom events
export const logEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
