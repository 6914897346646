// import {ForgeViewer} from "@lagarsoft/forge-viewer-react";

import {Buffer} from "buffer";
import {ForgeViewer} from "../../components/autodesk/ForgeViewer";

export const Viewer = () => {
    return <div className={`h-100`}>
        {/*<div style={{width: '500px', height: '500px', position: 'relative'}}>
            <ForgeViewer urn={Buffer.from(`urn:adsk.objects:os.object:hs888x/rstadvancedsampleproject.rvt`).toString(`base64`)}
                         accessToken={'eyJhbGciOiJSUzI1NiIsImtpZCI6IjY0RE9XMnJoOE9tbjNpdk1NU0xlNGQ2VHEwUV9SUzI1NiIsInBpLmF0bSI6ImFzc2MifQ.eyJzY29wZSI6WyJkYXRhOnJlYWQiLCJkYXRhOndyaXRlIiwiZGF0YTpjcmVhdGUiLCJ2aWV3YWJsZXM6cmVhZCIsImJ1Y2tldDpyZWFkIiwiYnVja2V0OmNyZWF0ZSJdLCJjbGllbnRfaWQiOiJQZEhieW8wYUdqOTIxTE1velBmZGFheENrVm4zRGFJRkJ2bE5YaEtmZFMzZEFYcFAiLCJpc3MiOiJodHRwczovL2RldmVsb3Blci5hcGkuYXV0b2Rlc2suY29tIiwiYXVkIjoiaHR0cHM6Ly9hdXRvZGVzay5jb20iLCJqdGkiOiJ5ZmdMUnJFSWtPQVpJc1hmOFJLZVpFWDVRRXFVMkpEcjI3ODVBb0lxZ2JpUVM2UjJOYVFnYmZDekxzUUZQUGxmIiwiZXhwIjoxNzEyODI1MDcwfQ.u_tDHPzSiX4RU9vcIP2aNHEuQK8yZuMrc9imc4aEdCvBp3b0wOJ_UQ8ap-T3G60VmZY5Tkx18lwHiX3CV2SwR8KEZRyp_DASPh8t1ptxMObzGYHN_yqXpQ0AVzzDNoyUpYSPsC5NT64XG34OcVe9AKpMQYVFJaVKNhfxFs-jOS1MCVnDKA9qShY-vFK7aQekAannvS6dG0O7kk5mGpASV9JScTh6CD9HpeGyDJcKm3gaADGIYPlCHJtby734UwtCdH3qBcrnwpepUkyd9J0pZ_rPwKT_5NJUftVqU5ezs44zREg2GD0D_ISiuRKzcvGhIDWfC8_VX5etpXw4KYMHGA'}/>
        </div>*/}
        {/*<ForgeViewer drawing={} />*/}
    </div>;
}