import {ReactElement, useEffect, useState} from "react";
import gridDark from "../../../assets/icons/grid_dark.svg";
import gridLite from "../../../assets/icons/grid_lite.svg";
import listDark from "../../../assets/icons/list_dark.svg";
import listLite from "../../../assets/icons/list_lite.svg";
import sortOrder from "../../../assets/icons/sort_order.svg";
import Projects from "./project/Projects";
import {useApiClient} from "../../../network/client/UseApiClient";
import {getProjects} from "../../../services/ProjectService";
import {Project} from "../../../models/Project";
import {NetworkState} from "../../../network/dto/ApiContract";
import {Spinner} from "../../../components/spinner/Spinner";
import "./managePage.styles.scss";
import {useNavigate} from "react-router-dom";


interface NavItem {
    id: string,
    label: string,
    element: ReactElement
}

export const McManagePage = () => {
    const [viewType, setViewType] = useState<'GRID'|'LIST'>("GRID");
    const [projects, projectNetworkState, error, fetchProjects] = useApiClient<Project[]>();
    const navigate = useNavigate();


    useEffect(()=> {
        fetchProjects(getProjects());
    }, [])


    return <div className={'px-5 py-4 h-100 managePage'}>
        <div className={`d-flex justify-content-between`}>
            <label className={`fw-bold`}>Projects</label>

            <div className={`d-flex`}>
                <div className={`me-3 cursor-pointer`} onClick={()=> {setViewType("GRID")}}><img src={viewType==="GRID"?gridDark:gridLite} /> <small className={viewType==="GRID"?'text-decoration-underline fw-bold':''}>Grid View</small></div>
                <div onClick={()=> {setViewType("LIST")}} className={`cursor-pointer`}><img src={viewType==="LIST"?listDark:listLite} /> <small className={viewType==="LIST"?'text-decoration-underline fw-bold':''}>List View</small></div>
            </div>
        </div>

        {projectNetworkState === NetworkState.Loading?<div className={`d-flex justify-content-center align-items-center w-100`}><Spinner /></div>:[
        <div className={`d-flex mt-5`}>
           <button className={`btn btn-primary`} onClick={()=>navigate(`project/addProject`)}>+ Add New</button>
            <div className={`ms-3 d-flex align-items-center sort`} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                <img src={sortOrder} className={'me-2'} />
                <small className={''}>Sort by: Most recent</small>
            </div>
        </div>,
        <div className={'mt-4'}>
            <Projects viewType={viewType} projects={projects||[]} />
        </div>]}
    </div>
}