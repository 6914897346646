import {Modal, ProgressBar, Spinner} from "react-bootstrap";
import PdfRenderer from "./PdfRenderer";
import {ServerFile} from "../../models/Document";
import {useApiClient} from "../../network/client/UseApiClient";
import {useFileUtils} from "../../utils/FIleUtils";
import {useToast} from "../toast/ToastManager";
import {downloadDocument} from "../../services/DocumentService";
import {v4} from "uuid";
import {NetworkState} from "../../network/dto/ApiContract";
import "./pdf_renderer.scss";

export const PdfViewerDialog = (props: {document?: ServerFile, onHide: ()=> void})=> {
    const [downloadDoc, downloadDocNetworkState, downloadError, downloadDocs, docRes] = useApiClient<Blob>();
    const {downloadFile} = useFileUtils();
    const toastManager = useToast();

    const downloadTheDocument=(id:string)=> {
        downloadDocs(downloadDocument(id)).then(res=> {
            downloadFile("", res);
        }).catch(err=> {
            if (err?.response?.status !== 403)
                toastManager.show({id: v4(), title: err.response.message, duration: 5000, isSuccess: false})
                // toastManager.show({id: v4(), title: `Error downloading document ${err.response.status === 404?': Not Found': ''}`, duration: 5000, isSuccess: false})
        })
    }


    return <Modal show={!!props.document} onHide={props.onHide} style={{zIndex: '9999',padding:'6px', height: '100%', minHeight: '100%'}} size={'lg'} className={`pdfRenderer`}>

        {props.document?

            <div style={{height: '100%'}} className={`d-flex flex-column`}>
                <div className={`d-flex justify-content-between pt-3 px-3 m-0 small m-2`} style={{textTransform: 'capitalize'}}>
                    <div>
                        <span>{props.document?.name}</span>
                    </div>
                    <div>
                        {downloadDocNetworkState === NetworkState.Loading ?
                            <Spinner className={'small me-3'}  style={{ height: '1.25em', width: '1.25em' }}/>
                         :
                            <span className={`bi bi-download cursor-pointer me-3`}  onClick={()=>downloadTheDocument(props.document?.id || '')}/>
                        }
                        <span className={`bi bi-x-lg cursor-pointer me-2`} onClick={()=> props.onHide()}/>
                    </div>
                </div>
                <PdfRenderer url={props.document?.url||''} highlight={props.document.highlight} page={props.document.page}  className={`flex-fill bg-light`} />
            </div>


            :<div>I Am Here</div>}
    </Modal>
}
export default PdfViewerDialog;