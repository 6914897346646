import axios, {AxiosError, CreateAxiosDefaults} from "axios";
import {API_BASE_URL} from "../../common/AppConstants";
import {KEY_ACCESS_TOKEN, useSessionManager} from "../../hooks/UseSessionManager";

const clientConfig: CreateAxiosDefaults = {
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
}
export const useAxios = () => {
    const axiosApiClient = axios.create(clientConfig);
    const {session, clearSession} = useSessionManager();
    axiosApiClient.interceptors.request.use((config) => {
        const token = localStorage.getItem(KEY_ACCESS_TOKEN);
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },error => {
        alert("fd")
        return Promise.reject(error);
        //TODO: Log Error
    });

    axiosApiClient.interceptors.response.use((res) => {

        return res;
    },(error: AxiosError) => {
        const token = localStorage.getItem(KEY_ACCESS_TOKEN);
        if (token && error.response?.status === 401) {
            clearSession();
        }
        //TODO: Log Error
        return Promise.reject(error);
    })
    return axiosApiClient;
}