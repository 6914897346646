import {UserRole} from "../models/UsersModel";

const getUserRoleLabel = (role: UserRole) => {
    switch (role) {
        case UserRole.Admin:
            return 'Admin';
        case UserRole.Member:
            return 'Member';
        case UserRole.SuperAdmin:
            return 'Super Admin';
        default:
            return "Unknown"
    }
}

export {getUserRoleLabel}