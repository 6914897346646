import {useState} from "react";

const ForgeViewer =  require('react-forge-viewer');

export const ForgeView = () => {
    const [view, setView] = useState<any>();
    const getForgeToken = () => {
        /* Normally, this would call an endpoint on your server to generate a public
        access token (using your client id and sercret). Doing so should yield a
        response that looks something like the following...
        */
        return {
            access_token:'eyJhbGciOiJSUzI1NiIsImtpZCI6IjY0RE9XMnJoOE9tbjNpdk1NU0xlNGQ2VHEwUV9SUzI1NiIsInBpLmF0bSI6ImFzc2MifQ.eyJzY29wZSI6WyJkYXRhOnJlYWQiLCJkYXRhOndyaXRlIiwiZGF0YTpjcmVhdGUiLCJ2aWV3YWJsZXM6cmVhZCIsImJ1Y2tldDpyZWFkIiwiYnVja2V0OmNyZWF0ZSJdLCJjbGllbnRfaWQiOiJQZEhieW8wYUdqOTIxTE1velBmZGFheENrVm4zRGFJRkJ2bE5YaEtmZFMzZEFYcFAiLCJpc3MiOiJodHRwczovL2RldmVsb3Blci5hcGkuYXV0b2Rlc2suY29tIiwiYXVkIjoiaHR0cHM6Ly9hdXRvZGVzay5jb20iLCJqdGkiOiJsbVUyOFBuaU9OcHcxY3QyS3ZGN1RyRGhlb3FvdGZiSmduRUI5dVZvdzlFVE10R2k1Mmt4QjFsVWxob0ZzWGlHIiwiZXhwIjoxNzEzMjM5OTQxfQ.JJo69_Ep-wONzmXH3vrfLuxkpBqa9GsimxPbTtEi9nJsRyw4bOrVTYHOl2iKcYt6M4KurjbTkQPGsr5FqZZ6KEBqOfImVotDZYEW2IzqhPt41esffXGyI88nQxhOBpzT6tV387xS1TWOQosJDtnwJn46eZkgLUKske1jNOnemLoWeqzK9N97npO0URFpO_DEP8e6MrFHpXb36qLtwB20orAAHaI63voK5bx5Gzt51pENRK9Fb_0jfBHxyGDgHtJZaVGhthvohfirbzH28q898fSXMUZ5HOxXWLGxkTiDzJpMTUlwIGzw4FiIjzScbDGJIj7IMN3UXy8qgOaO2XLHHA',
            expires_in: 1234,
            token_type: "Bearer"
    };
    }
    return <div>
        <ForgeViewer urn={'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6aHM4ODh4L3JzdGFkdmFuY2Vkc2FtcGxlcHJvamVjdC5ydnQ='}
                     accessToken={''} />
    </div>
}