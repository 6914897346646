import {ApiContract} from "../network/dto/ApiContract";

const GetAllProjectPartners = (id:number): ApiContract => {
    return {url: `/project/${id}/partners`, method: "GET"}
}
const GetSinglePartner =(id:string):ApiContract=>{
    return {url:`/partner/${id}`, method:'GET'}
}
const AddPartner=(id:string,name?:string,email?:string):ApiContract=>{
    const formData=new FormData();
    formData.append('name',name ||'');
    formData.append('email',email || '');
    return {url:`/project/${id}/partner`,method:'POST',requestBody:formData}
}

const updatePartner=(id?:string,name?:string,email?:string):ApiContract=>{
    const formData=new FormData();
    formData.append('name',name ||'');
    formData.append('email',email || '');
    return {url:`/partner/${id}`,method:'PUT',requestBody:formData}
}

const RemovePartner=(id?:string):ApiContract=>{

    return {url:`/partner/${id}`,method:'DELETE'}
}


const DeleteChatSummary=(chatSummaryId?:string):ApiContract=>{
    return {url:`/chat_summary/${chatSummaryId}`,method:'DELETE'}
}

const getPartnerProjectDocuments = (projectId: string, partnerId: string) : ApiContract => {
    return {url : `/api/public/project/${projectId}/partner/${partnerId}/documents` , method : "GET"}
}
const uploadImagePartner=(projectId:string,partnerId:string,documentType:string,file:File):ApiContract=>{
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentType',documentType)
    return {url:`/api/public/project/${projectId}/partner/${partnerId}/documents/upload` ,method:"POST",requestBody:formData,config: {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }}
}

export {GetAllProjectPartners,GetSinglePartner,AddPartner,RemovePartner,DeleteChatSummary,updatePartner,getPartnerProjectDocuments,uploadImagePartner}