import ImageUpload from "../../../components/upload/ImageUpload";
import React from "react";
import {Button} from "../../../components/button/Button";
import {NetworkState} from "../../../network/dto/ApiContract";
import {useApiClient} from "../../../network/client/UseApiClient";
import {UsersModel} from "../../../models/UsersModel";


export  const GeneralSetting =()=>{
    const [password,passwordNetworkData,AccountError,setPassword]=useApiClient<any>();



    return <div className={'my-4'}>

        <div>
            <div className={`row mb-3`}><span className={`text-secondary col-6 col-sm-3 col-md-3 col-lg-2 col-xl-1`}>Notifications</span> <span className={`col`}>
                <div className={'bg-white rounded px-4 py-3 '} >
                     <div className={'myAccount d-flex justify-content-center align-items-center ms-4'} style={{height:'60%'}}>

                    </div>

                </div>
                </span></div>
            <div className={`row`}><span className={`text-secondary col-6 col-sm-3 col-md-3 col-lg-2 col-xl-1`}>Password</span> <div className={`col`}>
                <div className={`bg-white rounded px-4 py-3`}>
                    <div className={'myAccount d-flex justify-content-center align-items-center ms-4'}>
                        <form id={`form_Project`} className={'flex-fill  m-4'} onSubmit={(e: any) => {
                            // onSubmit(e);
                        }}>
                            <strong className={'mb-4'}>Administrator Password</strong>
                            <div className="form-group mt-2 ">
                                <label className={'small mb-2'}><b>Change Password</b></label>
                                <input type="text" className="form-control rounded-0" id="exampleInputName"
                                       aria-describedby="nameHelp"
                                       value={''} name="password"  placeholder={'Enter new Password'}/>
                            </div>
                            <div className="form-group mt-2 mb-4 ">
                                <label className={'small mb-2'}><b>Confirm Password</b></label>
                                <input type="text" className="form-control rounded-0" id="exampleInputName"
                                       aria-describedby="nameHelp"
                                       value={''} name="password"  placeholder={'Enter new Password'}/>
                            </div>

                            <strong className={'mt-4 mb-2'}>Strength</strong>
                            <p className={'text-secondary fw-medium'}>Your Password must:</p>
                            <p className={'text-secondary fw-normal'}><i className="bi bi-check-circle-fill me-2" style={{color:'green'}}></i>Include lowercase and upper case characters</p>
                            <p className={'text-secondary fw-normal'}><i className="bi bi-check-circle-fill me-2" style={{color:'green'}}></i>Contain at least one number or symbol</p>
                            <p className={'text-secondary fw-normal'}><i className="bi bi-check-circle-fill me-2" style={{color:'green'}}></i>Between 8 and 31 characters long</p>


                            <div className={'d-flex justify-content-between mt-4'}>
                                <div></div>
                                <Button loading={passwordNetworkData === NetworkState.Loading} className={`btn btn-primary`}>Save</Button>
                            </div>
                        </form>


                    </div>
                </div>

            </div></div>
        </div>
    </div>
}
export default GeneralSetting;