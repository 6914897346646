import {ApiContract} from "../network/dto/ApiContract";

const ModelCheckerApi = (payload: FormData): ApiContract => {
    return {url: `http://dodda-develop-v14-group.westus.azurecontainer.io:80/model-checker/run`, method: "POST", requestBody: payload, config: {
        headers: {
            "Content-Type": "multipart/form-data"
        } }}
}
const ModelCheckerJavaApi = (docId: string): ApiContract => {
    return {url: `/autodesk/${docId}`, method: "GET"}
}
export {ModelCheckerApi, ModelCheckerJavaApi}