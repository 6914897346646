import {createContext, useState} from "react";
import {Project} from "../models/Project";
import {useSessionManager} from "./UseSessionManager";


export const DefaultProjectContext = createContext<{project?: Project, defaultProject?: Project, setDefaultProject?: (project: Project)=> void, setProject?: (project?: Project)=> void, activeBrain?: string, setActiveBrain?: (brainId: string|undefined) => void}>({});

export const DefaultProjectProvider = ({children}: {children: any})=> {
    const {defaultProject, setDefaultProject} = useSessionManager();
    const [project, selectProject] = useState<Project|undefined>(defaultProject);
    const [activeBrain, setActiveBrain] = useState<string|undefined>(defaultProject?.brainId);

    const putProject = (project?: Project, isDefault?: boolean) => {
        selectProject(project)
        if (project && isDefault) {
            setDefaultProject(project)
        }
    }

    return <DefaultProjectContext.Provider value={{project: project, activeBrain: activeBrain, defaultProject: defaultProject, setDefaultProject: (project)=> putProject(project, true), setProject: (project)=> putProject(project, false), setActiveBrain: setActiveBrain}}>
        {children}
    </DefaultProjectContext.Provider>
}