import {Button} from "../../../components/button/Button";
import {NetworkState} from "../../../network/dto/ApiContract";
import {useInputHandler} from "../../../hooks/UseInputHandler";
import {PasswordResetPayload, Token} from "../../../models/LoginModels";
import {useApiClient} from "../../../network/client/UseApiClient";
import {useNavigate} from "react-router-dom";
import {FormEvent} from "react";
import {PasswordResetApiContract} from "../../../services/LoginService";
import {v4} from "uuid";
import {useParams} from "react-router";
import {useToast} from "../../../components/toast/ToastManager";

export const ResetPassword = () => {
    const {payload, handleChange} = useInputHandler<PasswordResetPayload>({password: '', confirmPassword: ''});
    const [token, loginApiState, loginError, executeLoginApi] = useApiClient<Token>();
    // const {displayToast} = useToast();
    const toastManager = useToast();
    const navigate = useNavigate();
    const {uid} = useParams();

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (payload.password === payload.confirmPassword) {
            executeLoginApi(PasswordResetApiContract(uid||'', payload)).then(res=> {
                toastManager.show({id: v4(), title: "Password reset successfully!", isSuccess: true, duration: 4000})
            }).catch(err=> {});
        } else {
            toastManager.show({id: v4(), title: "New password is not matching with confirm password.", isSuccess: false, duration: 8000})
        }
    }
    return <div className={`h-100 w-100 d-flex align-self-center justify-content-center`}>
        <div className={`loginPanel shadow h-auto align-self-center`}>
            <h4 style={{fontWeight: '500'}} className={`text-center text-primary`}>Reset Password | Dodda-web Portal</h4>
            {loginError ? <div className={`text-danger mb-2`}>Error: {(loginError.response?.data as any)[`details`][0]} </div> : null}
            <form onSubmit={onSubmit} className={`mt-3`}>
                <div className={'mb-3'}>
                    <label htmlFor={`username`} className={`form-label`}>New Password</label>
                    <input className={`form-control`} type={`password`} id={`password`} name={`password`}
                           value={payload.password} onChange={handleChange} required/>
                </div>

                <div className={'mb-3'}>
                    <label htmlFor={`username`} className={`form-label`}>Confirm Password</label>
                    <input className={`form-control`} type={`password`} id={`confirmPassword`} name={`confirmPassword`}
                           value={payload.confirmPassword} onChange={handleChange} required/>
                </div>
                <div className={`d-flex justify-content-between`}>
                    <div></div>
                    <Button loading={loginApiState === NetworkState.Loading} className={`btn btn-primary`} loaderText={`Saving...`}>Save Password</Button>
                </div>
            </form>
        </div>
    </div>
}