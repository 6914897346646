import {AxiosRequestConfig, Method } from "axios";

export interface ApiContract {
    url: string,
    method: Method,
    cache?: boolean,
    requestBody?: any,
    config?: AxiosRequestConfig
}

export enum NetworkState {
    NotLoaded, Loading, Loaded, Failed
}