import "./modelChecker.scss";
import log from "../../assets/images/logo.png";
import {useEffect, useState} from "react";
import {ModelResultRow} from "./ModelResultRow";
import {useModelCheckerApiClient} from "../../network/client/UseModelCheckerApiClient";
import {ModelCheckerApi, ModelCheckerJavaApi} from "../../services/ModelCheckerService";
import {useApiClient} from "../../network/client/UseApiClient";
import {NetworkState} from "../../network/dto/ApiContract";

export const ModelChecker = (props: {docId?: string, docName?: string, onClose?: ()=> void}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [modelResult, modelState, modelApiError, runModelChecker] = useModelCheckerApiClient<any>();
    const [docModelResult, docModelState, docModelApiError, runDocModelChecker] = useApiClient<any>();

    useEffect(()=> {
        if (props.docId)
            runDocModel(props.docId)
    }, [props.docId])

    const handleSelection = (selection: File[]) => {
        setFiles((files)=> [...files, ...selection])

        const payload = new FormData();
        payload.append("file", selection[0]);
        runModelChecker(ModelCheckerApi(payload)).then(res => {
            console.log(res)
        }).catch((err) => {

        });
    }

    const runDocModel = (docId: string) => {
        runDocModelChecker(ModelCheckerJavaApi(docId)).then(res=> {
            console.log("Model Res: ", res)
        }).catch(err=> {})
    }

    return <div className={`modelChecker`}>
        {/*<div className={`sidebar`}>
            <div className={`header py-2`}>
                <p className={`text-center fw-bold fs-6 text-white`}>Model Checker</p>
                <div className={`inputArea`}>
                    <label>Upload File</label>
                    <FileInput label={'Select IFC'} onChange={handleSelection} className={`mt-1`} color={`white`} accept={`.ifc`}/>
                    <span className={`help`}>Upload an IFC file to analyse</span>
                </div>
            </div>
            <hr className={`m-0`}/>
            <div className={`mt-3 px-3`}>
                <label className={`small fw-bold text-decoration-underline mb-2`}>UPLOADED FILES</label>
                {files.length>0?files.map((file, idx) => (<div className={`mb-3 lh-1`}><span className={``}>{idx + 1}.</span> <span
                    className={`small`}>{file.name}</span></div>)):<p style={{fontSize:'12px'}} className={`text-secondary`}>You haven't uploaded any file yet!</p>}

            </div>
        </div>*/}

        <main className={`h-100`}>
            <div className={`h-100 d-flex flex-column`}>
                <div className={`d-flex justify-content-between p-3`}>
                    <img src={log} style={{width: '70px'}} />
                    <button className={`btn btn-sm py-0`} onClick={()=> {
                        if (props.onClose) {
                            props.onClose();
                        }
                    }}><span className={`bi bi-x-lg`} /></button>
                </div>

                <div className={`mt-2 d-flex flex-column  align-items-center`}>
                    <h5 className={`mb-0`}>Model Checker</h5>
                    {props.docName?<p>{props.docName}</p>:null}
                </div>
                <hr className={`mb-0 mt-1`}/>
                <div className={`p-3 h-100`}>
                    {(docModelState === NetworkState.Loaded && docModelResult)?<table className={`contentTable table table-responsive table-bordered`}>
                        <thead>
                        <tr>
                            <th style={{width: '20px'}} className={`small`}><span className={`bi bi-plus-slash-minus`} /></th>
                            <th>Object</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(docModelResult).map(key=> {
                            const resKey = Object.keys(docModelResult[key])[0];
                            return <ModelResultRow label={resKey} dataset={docModelResult[key][resKey]}  />
                        })}
                        </tbody>
                    </table>: docModelState===NetworkState.Loading?<div className={`w-100 flex-fill d-flex justify-content-center align-items-center h-100`}>
                        <small>Please Wait, Analysing...</small></div>: docModelState===NetworkState.Failed?<div className={`w-100 flex-fill d-flex justify-content-center align-items-center text-danger h-100`}>Error Occur: {docModelApiError?.message}</div>:null}
                </div>
            </div>
        </main>
    </div>;
}