import aiBot from "../../assets/icons/ai_bot.svg";
import Markdown from "react-markdown";
import moment from "moment";
import {ServerFile} from "../../models/Document";

export const ChatBubbleLet = (props: {children: any, time?: string, onShowDocument?: (document?: ServerFile)=> void}) => {
    return <div className={`chatBubbleLeft`}>
        <div className={`chatUserInfo`}>
            <div className={`chatUserIndicator`}><img src={aiBot}/>
            </div>
            <label>A.I.</label>
        </div>

        <div>
            <div className={`d-flex flex-column`}>
                <div className={`d-flex`}>
                    <div className={`chatContent`}>
                        <Markdown className={`p-0`}>
                            {props.children}
                        </Markdown>
                    </div>
                    {/*<div className={`pt-1`}>
                        <span className={`p-2 text-decoration-underline fst-italic`}>Reference:</span>
                        <div className={`mt-2 overflow-auto customScrollbar align-items-center`} style={{maxWidth: '100%', lineHeight: "normal", wordWrap: 'break-word'}}>
                           {props.referenceDocs?.filter(doc=> doc.fileType.toLowerCase() !== 'rvt')?.map((doc, idx) => (<DocChip key={doc.documentId} idx={idx+1} doc={doc} onShowDocument={props.onShowDocument} /> )) }
                        </div>
                    </div>*/}
                </div>

                <small className={`ms-3 text-secondary p-2`}>
                    {moment(props.time)?.format("DD MMM | HH:mm")}
                </small>

            </div>
        </div>

    </div>
}