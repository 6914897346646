import {TabMenuItem} from "../tab/TabMenuItem";
import './partnerDocs.styles.scss';
import {ReactElement, useEffect, useState} from "react";
import {useApiClient} from "../../network/client/UseApiClient";
import {PartnerLinks} from "./links/PartnerLinks";
import {PartnerChatSummaries} from "./chat/PartnerChatSummaries";
import {PartnerFiles} from "./files/PartnerFiles";
import {getPartnerProjectDocuments} from "../../services/PartnerService";
import {PartnerImages} from "./images/PartnerImages";


export const PartnerDocs = (props: { projectId: string, partnerId: string }) => {
    const [activeTab, setActiveTab] = useState<string>('summary');
    const [partnerProjectDocs, cpDocNetworkState, cpDocError, fetchPartnerProjectDocuments] = useApiClient<any>();

    useEffect(() => {
        fetchPartnerProjectDocuments(getPartnerProjectDocuments(props.projectId, props.partnerId))
    }, []);

    const getData = (type: string): any[] => {
        switch (type) {
            case 'summary':
                return partnerProjectDocs?.data.chatSummaries || [];
            case 'images':
                return partnerProjectDocs?.data.images || [];
            case 'links':
                return partnerProjectDocs?.data.links || [];
            case 'files':
                return partnerProjectDocs?.data.documents || [];
            default:
                return []
        }
    }

    const renderLayout = (type: string, data: any[]): ReactElement => {
        switch (type) {
            case 'summary':
                return renderSummary(data);
            case 'images':
                return renderImages(data);
            case 'links':
                return renderLinks(data);
            case 'files':
                return renderFiles(data);
            default:
                return <div></div>
        }
    }

    const renderSummary = (data: any[]) => {
        return <PartnerChatSummaries data={data}/>
    }
    const renderLinks = (data: any[]) => {
        return <PartnerLinks data={data}/>
    }

    const renderImages = (data: any[]) => {
        return <PartnerImages data={data} partnerId={props.partnerId} projectId={props.projectId} onSuccess={() =>
            fetchPartnerProjectDocuments(getPartnerProjectDocuments(props.projectId, props.partnerId))

        }/>
    }
    const renderFiles = (data: any[]) => {
        return <PartnerFiles data={data}/>
    }

    return <div className={'card-box shadow-sm clientDocumentCard'}>

        <div>
            <div className={``}><p>Documents</p></div>
            <div className={` d-flex justify-content-start mt-0`}>
                <nav className="nav flex-column flex-sm-row nav-tabs my-3">
                    <TabMenuItem label={'Chat summary'}
                                 isActive={activeTab === 'summary'} onClick={() => setActiveTab('summary')}/>
                    <TabMenuItem label={'Images'}
                                 isActive={activeTab === 'images'} onClick={() => setActiveTab('images')}/>
                    <TabMenuItem label={'Links'}
                                 isActive={activeTab === 'links'} onClick={() => setActiveTab('links')}/>
                    <TabMenuItem label={'Files'}
                                 isActive={activeTab === 'files'} onClick={() => setActiveTab('files')}/>
                </nav>
            </div>
            <div>
                {
                    renderLayout(activeTab, getData(activeTab))
                }
            </div>
        </div>
    </div>
}