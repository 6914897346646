import {Fragment, useEffect, useState} from "react";
import {ResponseBack} from "../../models/AppTypes";
import Select from "react-dropdown-select";

export interface Option{
    value:number;
    label:string

}
export const SelectDropDown=(props:{values?:number[] | null, options?: Option[],onSelect?:ResponseBack,label?:string,placeholders?:string})=>{
    const [selectedOptions,setSelectedOptions]=useState<Option[]>([]);


    useEffect(() => {
        if(props.values && props.options){
            setSelectedOptions(props.options?.filter(option=>(props.values?.indexOf(option.value) !== -1)) || [])
        }
    }, [props.values, props.options])
    const handleSelectChange=(options: Option[])=>{
        setSelectedOptions(options);
        if (props.onSelect)
            props.onSelect(options);
    }
    return<Fragment>
        <Select options={props.options || []}
                onChange={handleSelectChange}
                values={selectedOptions}
                multi
                placeholder={props.placeholders ?? "Select Members"}></Select>
    </Fragment>

}