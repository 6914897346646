import React, {useRef, useState} from 'react';


export const AttachmentComponent = (props:{projectId?:number, required?: boolean, onChange: (file: File|null)=> void})=>{
    const[files,setFiles]=useState([]);
    const[selectedFile,setSelectedFile]=useState<any>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileChange=(e:any)=>{
        const selected =e.target.files[0];
        if(selected){
            setSelectedFile(selected);
            props.onChange(selected);
        } else {
            props.onChange(null)
        }
    };

    const handleFileUpload =()=>{
        fileInputRef.current?.click();
    }

   const removeFile=(index:number)=>{
        const newFiles = [...files];
        newFiles.splice(index,1);
        setFiles(newFiles);
   };
    
    return<div>
         <div className={`py-1 px-2 border d-flex h-38`} onClick={handleFileUpload}>
             <span className={`btn btn-sm btn-primary rounded-0 py-0 small`}>Choose file</span>
             {selectedFile ?<small className={`ms-1`}><i className={`bi bi-file-earmark me-1`}/>{`${selectedFile.name}`}</small>:null}
         </div>

        {
            files.map((file:any,index)=>(
                <div key={index}>
                    <span>{file?.name || 'file'}</span>
                    <button onClick={()=>removeFile(index)}>Remove</button>
                </div>
            ))
        }
        <input type={'file'} hidden={true} ref={fileInputRef} onChange={handleFileChange}
               name="images_uploads"
               id="images_uploader"
               className="visually-hidden form-control" required={props.required}/>
    </div>
}

export default AttachmentComponent;
