import {Link} from "react-router-dom";
import "./tabMenu.styles.scss";

export const TabMenuItem = (props: { icon?: any, label: string, pathname?: string, isActive: boolean, onClick?: ()=> void }) => {
    return props.pathname ? <Link
        className={`flex-sm-fill text-sm-center nav-link d-flex flex-column justify-content-center align-items-center tabMenuItem ${(props.isActive) ? 'active' : ''}`}
        aria-current="page" to={props.pathname ? props.pathname : ''}>{props.icon ?
        <img src={props.icon} className={`icon`}/> : null}{props.label}</Link> :
        <div
        className={`flex-sm-fill text-sm-center nav-link d-flex flex-column justify-content-center align-items-center tabMenuItem cursor-pointer ${(props.isActive) ? 'active' : ''}`}
        aria-current="page" onClick={()=> {
            if (props.onClick) {
                props.onClick();
            }
        }}>{props.icon ?
        <img src={props.icon} className={`icon`}/> : null}{props.label}</div>
}