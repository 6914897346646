import React, {useContext} from "react";
import ReactDOM from "react-dom";
import "./Toast.css";
import Toast, {ToastProps} from "./Toast";
import ToastContext from "react-bootstrap/ToastContext";

interface ToastOptions{
    id? : string;
    title: string;
    isSuccess? : boolean;
    duration? : number;
    showLink?:boolean
    copyLink?:string,
}

export class ToastManager{
    private containerRef : HTMLDivElement;
    private toasts: ToastProps[] = [];
    private toastContainerId = 'toast';

    constructor() {
        const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
        const toastContainer = document.createElement("div") as HTMLDivElement;
        toastContainer.id = "toast";
        toastContainer?.classList?.add('toast-container-main');
        // (document.getElementById('toast'))?.classList.add('toast-container-main')
        body.insertAdjacentElement("beforeend", toastContainer);
        this.containerRef = toastContainer;
    }

    public show(options : ToastOptions) : void{
        if(options?.isSuccess ?? true){
            (document.getElementById('toast'))?.classList.replace('toast-container-main-failed','toast-container-main');
        }else{
            (document.getElementById('toast'))?.classList.replace('toast-container-main','toast-container-main-failed')
        }
        const toastId = Math.random().toString(36).substring(2,9);
        const toast: ToastProps = {
            id : toastId,
            ...options,
            destroy : () => this.destroy(options.id ?? toastId),
        };

        this.toasts = [toast, ...this.toasts];
        this.render();
    }

    public destroy(id: string): void {
        this.toasts = this.toasts.filter((toast : ToastProps) => toast.id !== id);
        this.render();
    }

    private render(): void {
        const toastList = this.toasts.map((toastProps : ToastProps) => (
            <Toast key={toastProps.id} {...toastProps}></Toast>
        ));
        ReactDOM.render(toastList, this.containerRef);
    }
}

export const toast = new ToastManager();

export const useToast = () => {
    return toast;
}
