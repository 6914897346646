import { Fragment, useEffect, useState } from "react";
import './memberList.scss';
import { DocumentMember } from "../../../../../../../../models/Documents";


export const MemberList = (props: {
    data?: DocumentMember
}) => {

    const hashString = (str: string): number => {
        let hash = 5381;
        const combinedString = str + "";

        for (let i = 0; i < combinedString.length; i++) {
            const char = combinedString.charCodeAt(i);
            hash = (hash * 33) ^ char;
        }

        return hash >>> 0;
    };

    const getBackgroundColor = (userId: string): string => {
        const availableColors = [
            '#336699', '#666666', '#ff6600', '#A45209', '#533419',
            '#800080', '#008080', '#FFA500', '#003366', '#FF00FF'
        ];
        const hash = hashString(userId);
        const index = hash % availableColors.length;
        return availableColors[index];
    };

    const [backgroundColor, setBackgroundColor] = useState<string>('#000000');

    useEffect(() => {
        if (props.data?.id) {
            const hash = hashString(props.data.id);
            const availableColors = [
                '#336699', '#666666', '#ff6600', '#A45209', '#533419',
                '#800080', '#008080', '#FFA500', '#003366', '#FF00FF'
            ];
            const index = hash % availableColors.length;
            const selectedColor = getBackgroundColor(props.data.id);
            setBackgroundColor(selectedColor);
        }
    }, [props.data?.id]);

    const getInitials = () => {
        const words = props?.data?.fullname?.split(' ');
        const initials = words?.map((word) => word[0]?.toUpperCase());
        return initials?.join('');
    }

    return (
        <Fragment>
            {props?.data?.memberImg ?
                <img src={`https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60`} className={'imgThumbnail'} />
                :
                <p
                    className={'user-filter-chip d-flex justify-content-center align-items-center'}
                    style={{
                        height: '30px',
                        width: '30px',
                        backgroundColor: `${backgroundColor}`,
                        color: '#fff',
                        borderRadius: '3rem',
                        transition: '.2s',
                        marginBottom:0,
                        marginRight: "-0.4rem"
                    }}
                >
                    {getInitials()}
                </p>
            }
        </Fragment>
    );
}
