import {ApiContract} from "../network/dto/ApiContract";

const AddIntegration = (payload:any): ApiContract => {
    return {url: `/integrations`, method: 'POST',requestBody: payload}
}
const GetAllIntegration = (): ApiContract => {
    return {url: `/integrations`, method: 'GET'}
}

const IntegrationDetail=(integrationId?:string):ApiContract =>{
    return {url:`/integrations/${integrationId}`,method:'GET'}
}
const UpdateIntegration=(integrationId?:string,payload?:any):ApiContract =>{
    return {url:`/integrations/${integrationId}`, method:'PUT',requestBody:payload}
}
const RemoveIntegration=(integrationId?:string):ApiContract =>{
    return {url:`projects/integrations/${integrationId}`,method:"DELETE"}
}
export {
AddIntegration,GetAllIntegration,IntegrationDetail,UpdateIntegration,RemoveIntegration
}