import "./drawing_strip.scss"
import {DrawingCard} from "./DrawingCard";
import {Drawing} from "../../../models/Drawing";
import {DocCard} from "./DocCard";
import {ServerFile} from "../../../models/Document";
import {Spinner} from "../../../components/spinner/Spinner";
export const DrawingsStrip = (props: {drawings: Drawing[], showLoader: boolean, onShowDrawing: (drawing: Drawing)=> void, onShowDoc: (file?: ServerFile)=> void, onLoadMore: ()=> void}) => {
    const renderDrawingCard = (doc: Drawing) => {
        return <DrawingCard drawing={doc} onClick={()=> props.onShowDrawing(doc)} />;
    }

    const renderDoc = (doc: Drawing) => {
        switch (doc.fileType) {
            case "rvt":
                return renderDrawingCard(doc);
            default:
                return <DocCard drawing={doc} onClick={(file?: ServerFile)=> {
                    if (file) {
                        props.onShowDoc({...file, page: doc.page, highlight: doc.content})
                    }
                }} />
        }
    }
    return <div className={`drawingStrip align-self-start`}>
        {props.drawings.map(renderDoc)}
        <div className={'d-flex justify-content-center align-items-center'} style={{minWidth: '90px'}}>{(props.drawings.length % 6) === 0? props.showLoader?<div><Spinner /> </div>:<button className={`btn`} onClick={()=> props.onLoadMore()}>Load More</button>:null}</div>
    </div>
}