import {ApiContract} from "../network/dto/ApiContract";
import {LoginRequestPayload, PasswordResetPayload, PasswordResetRequestPayload} from "../models/LoginModels";

const LoginApiContract = (payload: LoginRequestPayload): ApiContract => {
    return {url: '/api/public/login', method: "POST", requestBody: payload}
}
const PasswordResetRequestApiContract = (payload: PasswordResetRequestPayload): ApiContract => {
    return {url: '/password/reset/link', method: "POST", requestBody: payload}
}

const PasswordResetApiContract = (key: string, payload: PasswordResetPayload): ApiContract => {
    return {url: `/password/reset/${key}`, method: "POST", requestBody: payload}
}
const LoginUser=(userId?:string):ApiContract =>{
    return {url:`/api/admin/users/${userId}`,method:'GET'}
}
export {LoginApiContract,LoginUser, PasswordResetRequestApiContract, PasswordResetApiContract}