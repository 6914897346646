import React, {Fragment, useState} from 'react';
import './integrationList.scss';
import ConfirmationModal from "../../../../../../../components/confirmationDialog/ConfirmationModal";
import {Integration} from "../../../../../../../models/Integration";
import menu from "../../../../../../../assets/icons/menu.svg";
import {DropdownButton, DropdownItem} from "react-bootstrap";
import {IntegrationFormModal} from "../integrationModal/IntegrationFormModal";
import {useApiClient} from "../../../../../../../network/client/UseApiClient";
import {RemoveClient} from "../../../../../../../services/ClientService";
import {RemoveIntegration} from "../../../../../../../services/IntegrationService";
import {useParams} from "react-router";


interface ProjectCardProps {
    defaultValue?: Integration;
    onSuccess:()=>void;
    onDelete:()=>void
}

const IntegrationListCard: React.FC<ProjectCardProps> = ({defaultValue,onSuccess,onDelete}) => {
    const [data, setData] =useState(defaultValue);
    const [showDelete,setShowDelete]=useState<boolean>(false);
    const [option, setOption] = useState<any[]>([{value: 'delete', label: 'Delete'}]);
    const[showEditModal,setShowEditModal]=useState<boolean>(false);
    const [targetIntegration,setTargetIntegration]=useState<string | undefined>(undefined);
    const [removeIntegration, removeIntegrationNetworkState, removeIntegrationError, removeIntegrationApi] = useApiClient<Integration>();
    const params = useParams<{projectId: string}>();

    const deleteIntegration=()=> {
        removeIntegrationApi(RemoveIntegration(data?.id)).then(() => {
           onDelete();
        });

    }

        return (
        <Fragment>
            <div className={'cardIntegration w-100 overflow-hidden p-3 mb-3'}>
                <div className={'d-flex justify-content-between'}>
                    <strong className={'text-primary'}>{data?.name ? data.name : ''}</strong>
                    <div className={'d-flex'}>
                        {data?.active === true ?
                            <span><i className="bi bi-check-circle-fill me-1" style={{color:'#1D4A93'}}></i></span>
                        :
                        ''
                        }
                        <DropdownButton
                            drop={`start`}
                            title={<img src={menu} onClick={() => ''}/>}
                            className={`text-secondary`}>
                            <DropdownItem onClick={()=> {
                                setTargetIntegration(data?.id);
                                setShowEditModal(true)
                            }}><span className={`bi bi-pencil me-2`}/> Edit</DropdownItem>

                            <DropdownItem className={`text-danger`} onClick={()=>setShowDelete(true)}><span className={`bi bi-trash me-2 text-danger`}/> Delete</DropdownItem>
                        </DropdownButton>
                    </div>
                </div>
                <p className={'mt-2 text-secondary'}>{data?.description}</p>
                <div className={`form-group`}>
                    <label className={`form-label mb-0`}><strong>Integration Type</strong></label>
                    <p className={'text-secondary fw-8'}>{data?.integrationType}</p>
                </div>
                <div className={`form-group`}>
                    <label className={`form-label`}><strong>Webhook URL</strong></label>
                    <input type={`text`} className={`form-control text-small`} disabled value={data?.webhookUrl} onClick={()=> navigator.clipboard.writeText(`${data?.webhookUrl}`)}/>
                </div>
            </div>
            <ConfirmationModal title={'Delete Integration'} message={'Are You sure you want to delete this?'} onSubmit={()=>{
                deleteIntegration();
                setTargetIntegration(undefined);
                setShowDelete(false);
            }} onCancel={()=>setShowDelete(false)} show={showDelete} btnText={'Delete'}/>

            <IntegrationFormModal show={showEditModal} onCancel={()=> {
                setShowEditModal(false);
                setTargetIntegration(undefined);
            }} onSuccess={()=>{
                setShowEditModal(true);
                setTargetIntegration(undefined);
              onSuccess();
            }} defaultValue={targetIntegration}/>
        </Fragment>
    );
}

export default IntegrationListCard;