import React, {FormEvent, ReactElement, useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import './ZipIndexingConfirmationModal.css'
export interface ModalProps {
    title: string;
    message: string | ReactElement;
    confirmationMessage?: string;
    itemName?: string;
    buttonClass?: string,
    onSubmit: () => void;
    onCancel: () => void;
    show: boolean;
    valid? :boolean;
    btnText:string;
}

const ZipIndexingConfirmationModal: React.FC<ModalProps> = (props) => {
    const [checkbox,setCheckBox] = useState<boolean>(false);

    useEffect(()=>{
        setCheckBox(false);
    },[props.show]);


    return (
        <div>
            <Modal show={props.show} onHide={() =>{props.onCancel();setCheckBox(false)}} style={{zIndex: 3001}} backdropClassName={`dialog-backdrop`}>
                <ModalBody>
                    <h5 className={`mb-2`}>{props.title}</h5>
                    {props.itemName && props.itemName !== ''?<h5 id="car-1 ">{props.itemName}</h5>:null}
                    <p id="para mt-0">{props.message}</p>
                    {props?.valid ?? false ? "" : <div className="d-flex align-items-center" style={{marginTop: "24px",cursor:'pointer'}} onClick={(e) => setCheckBox(!checkbox)}>
                        <input type="checkbox" className="me-2" checked={checkbox}/>
                        <p className={`m-0`}>{"Check to confirm."}</p>
                    </div>}
                    <div className="col-md-12 d-flex justify-content-end" style={{marginTop:"16px"}}>
                        <button  style={{fontSize:"15px"}} disabled={!checkbox} className={`btn ${checkbox ? props?.buttonClass ?? "btn-danger text-white" : "btn-secondary"} mx-3 d-flex`} onClick={props.onSubmit}>{props?.btnText??" "}</button>
                        <div style={{fontSize:"15px"}} className="btn cursor-pointer d-flex" onClick={() => {props.onCancel(); setCheckBox(false)}}>Cancel</div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ZipIndexingConfirmationModal;
