import './teamMemberDocs.styles.scss';
import {ReactElement, useEffect, useState} from "react";
import {TeamImages} from "./images/TeamImages";
import {TeamMemberLinks} from "./links/TeamMemberLinks";
import {TeamMemberChatSummaries} from "./chat/TeamMemberChatSummaries";
import {TeamMemberFiles} from "./files/teamMemberFiles";
import {getTeamMemberProjectDocuments} from "../services/TeamService";
import {useApiClient} from "../network/client/UseApiClient";
import {TabMenuItem} from "../components/tab/TabMenuItem";


export const TeamMemberDocs = (props: {projectId: string, teamMemberId: string}) => {
    const [activeTab, setActiveTab] = useState<string>('summary');
    const [teamMemberDocs, cpDocNetworkState, cpDocError, fetchTeamMemberDocs] = useApiClient<any>();

    useEffect(() => {
        fetchTeamMemberDocs(getTeamMemberProjectDocuments(props.projectId, props.teamMemberId))
    }, []);

    const getData = (type: string): any[] => {
        switch (type) {
            case 'summary':
                return teamMemberDocs?.data.chatSummaries||[];
            case 'images':
                return teamMemberDocs?.data.images||[];
            case 'links':
                return teamMemberDocs?.data.links||[];
            case 'files':
                return teamMemberDocs?.data.documents||[];
            default:
                return []
        }
    }

    const renderLayout = (type: string, data: any[]): ReactElement => {
        switch (type) {
            case 'summary':
                return renderSummary(data);
            case 'images':
                return renderImages(data);
            case 'links':
                return renderLinks(data);
            case 'files':
                return renderFiles(data);
            default:
                return <div></div>
        }
    }

    const renderSummary = (data: any[]) => {
        return <TeamMemberChatSummaries data={data} />
    }
    const renderLinks = (data: any[]) => {
        return <TeamMemberLinks data={data} />
    }

    const renderImages = (data: any[]) => {
        return <TeamImages data={data} teamMemberId={props.teamMemberId} projectId={props.projectId} onSuccess={()=>
            fetchTeamMemberDocs(getTeamMemberProjectDocuments(props.projectId, props.teamMemberId))

        }/>
    }
    const renderFiles = (data: any[]) => {
        return <TeamMemberFiles data={data} />
    }

    return <div className={'card-box shadow-sm teamMemberDocumentCard'}>

        <div>
            <div className={``}><p>Documents</p></div>
            <div className={` d-flex justify-content-start mt-0`}>
                <nav className="nav flex-column flex-sm-row nav-tabs my-3">
                    <TabMenuItem label={'Chat summary'}
                                 isActive={activeTab==='summary'} onClick={()=> setActiveTab('summary')}/>
                    <TabMenuItem label={'Images'}
                                 isActive={activeTab==='images'} onClick={()=> setActiveTab('images')}/>
                    <TabMenuItem label={'Links'}
                                 isActive={activeTab==='links'} onClick={()=> setActiveTab('links')}/>
                    <TabMenuItem label={'Files'}
                                 isActive={activeTab==='files'} onClick={()=> setActiveTab('files')}/>
                </nav>
            </div>
            <div>
                {
                    renderLayout(activeTab, getData(activeTab))
                }
            </div>
        </div>
    </div>
}