import Skeleton from "react-loading-skeleton";

export const ChatScreenShimmer=()=>{


    return <div>
        <div className={`chatBubbleLeft`}>
            <div className={`chatUserInfo`}>
                <div className={`chatUserIndicator`}>
                    <Skeleton height={'32px'} width={'30px'}/>
                </div>
                {/*<label>A.I.</label>*/}
            </div>

            <div>
                <div className={`chatContent`} style={{
                    minWidth:'180px'
                }}>
                    <Skeleton height={'200px'} />
                </div>
            </div>
        </div>
        <div className={`chatBubbleRight mt-4`}>
            <div className={`chatContent`} style={{
                minWidth:'120px'
            }}>
                <Skeleton height={'200px'} width={'250px'}/>
            </div>
            <div className={`chatUserInfo`}>
                <div className={`chatUserIndicator`}>
                    <Skeleton height={'32px'} width={'30px'}/>

                </div>
                {/*<span>You</span>*/}
            </div>
        </div>

        </div>
}