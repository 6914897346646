import {useApiClient} from "../../network/client/UseApiClient";
import {UsersModel} from "../../models/UsersModel";
import React, {useEffect, useState} from "react";
import {
    getUserApiContract,
    hardDeleteUserApiContract,
    softDeleteUserApiContract,
    undoSoftDeleteUserApiContract
} from "../../services/UserService";
import {Page} from "../../network/dto/Page";
import {NetworkState} from "../../network/dto/ApiContract";
import Pagination from "../../components/pagination/Pagination";
import CustomSwitcher from "../../components/switcher/CustomSwitcher";
import AddEditUserModal from "./AddEditUserModal";
import ResetPasswordModal from "./ResetPasswordModal";
import {Spinner} from "../../components/spinner/Spinner";
import ConfirmationModal from "../../components/confirmationDialog/ConfirmationModal";
import './../../pages/management-console/manage/project/cards/projectCard.scss';
import './../management-console/manage/project/projectCardList.scss';
import {Initials} from "../../components/inititals/Initials";
import {UserProjectsModal} from "../../components/userProjects/UserProjectsModal";
import {v4} from "uuid";
import {toast, useToast} from "../../components/toast/ToastManager";
import {DropdownButton, DropdownItem} from "react-bootstrap";
import menu from "../../assets/icons/menu.svg";
import "./users.scss";
import ProjectIcon from "../../assets/icons/project.svg";

const Users = () => {

    const [users, usersNetworkState, usersError, fetchUsers] = useApiClient<Page<UsersModel>>();
    const [_, deleteUserNetworkState, deleteUserError, deleteUser] = useApiClient<Page<UsersModel>>();
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [targetUser, setTargetUser] = useState<string | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [showPasswordEditor, setShowPasswordEditor] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [activeUser, setActiveUser] = useState<UsersModel>();
    const [showProjectPermissions, setShowProjectPermissions] = useState<boolean>(false);
    const toast = useToast();

    useEffect((page?: number) => {
        fetchUsers(getUserApiContract(page, pageSize))
        setPageSize(pageSize)
    }, []);

    useEffect(() => {
        if (usersNetworkState == NetworkState.Loaded) {
            setCurrentPage(users?.number || 0);
            setTotalPages(users?.totalPages || 0);
            setTotalElements(users?.totalElements || 0);
        }
    }, [usersNetworkState])

    useEffect(() => {
        if (currentPage !== 0) {
            fetchUsers(getUserApiContract(currentPage, pageSize));

        }
    }, [currentPage, pageSize]);

    useEffect(() => {
        if (deleteUserNetworkState === NetworkState.Loaded) {
            fetchUsers(getUserApiContract(currentPage, pageSize))
        }
    }, [deleteUserNetworkState]);

    const handleEdit = (item: any) => {
        setTargetUser(item.id);
        setShowEditor(true);
    };

    const handleEditPassword = (item: any) => {
        setTargetUser(item.id);
        setShowPasswordEditor(true);
    };

    return <div className={'py-4 h-100 managePage'}>
        <div className={`d-flex justify-content-between mb-4`}>
            {/*<label className={`fw-bold`}>Users</label>*/}

            <div className={`d-flex`}>
                <button className={"btn btn-primary text-white rounded-1 btn-add"}
                        onClick={() => {
                            setShowEditor(true);
                            setTargetUser(undefined);
                        }}>+ New User
                </button>
            </div>
        </div>
        <div className={'card-body mt-4'}>
            {/*<div className={'d-md-flex justify-content-between align-items-center'}>*/}
            {/*    <div className="form-group searchBar border rounded-2 bg-transparent">*/}
            {/*        /!*<i className="bi bi-search formFeedback bg-transparent text-primary fw-bold"></i>*!/*/}
            {/*        <input type="search" className="form-control formControl bg-light bg-white"*/}
            {/*               placeholder="Search here..." onChange={() => {*/}
            {/*        }}/>*/}
            {/*    </div>*/}
            {/*    */}
            {/*</div>*/}
            {usersNetworkState === NetworkState.Loading ? <div className={`w-100 d-flex justify-content-center`}>
                    <Spinner/>
                </div> :
                <div className={`projects`}>
                    <table className={`text-center w-100 border-0`}>
                        <thead>
                        <tr>
                            <th><small>Sr.No</small></th>
                            <th><small>User email</small></th>
                            <th><small>Full Name</small></th>
                            <th><small>Projects</small></th>
                            <th><small>Active</small></th>
                        </tr>
                        </thead>

                        <tbody>
                        {users?.content?.map((res, index) => (

                            <tr key={res.id}>
                                {/*<td className={''}>*/}
                                {/*    <div className={`d-flex align-items-center`}>*/}
                                {/*        <img src={''} className={'imgThumbnail me-2'}>*/}
                                {/*        </img>*/}
                                {/*        <small className={`fw-bold`}>{data?.name}</small>*/}
                                {/*    </div>*/}
                                {/*</td>*/}
                                {/*<td className={'user-profile d-flex justify-content-center align-items-center'}><img src={'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'} className={'imgThumbnail'} >*/}

                                {/*</img></td>*/}
                                <td className={`${res.active === false ? 'bg-light' : ''}`}>{index + 1}.</td>
                                <td className={`${res.active === false ? 'bg-light' : ''}`}>{res.username}</td>
                                <td className={`${res.active === false ? 'bg-light' : ''}`}>{res.fullname}</td>
                                <td className={`${res.active === false ? 'bg-light' : ''}`}><div className={`d-inline-flex`}>
                                    {((res.permissions?.length||0)>3?res.permissions?.slice(0, 3):res.permissions)?.map(permission=> <Initials onClick={()=> {setActiveUser(res);}} title={`Project: ${permission.project.name}\nRole: ${permission.role}`} name={permission.project.name||''} imgUrl={permission.project.profileImageUrl} />)}
                                    {((res.permissions?.length||0)>3)?<Initials onClick={()=> {setActiveUser(res); setShowProjectPermissions(true)}} name={`+${(res.permissions?.length||0)-3}`} />:null}
                                    <Initials onClick={()=> {setActiveUser(res); setShowProjectPermissions(true)}} name={''} className={'bg-white p-1 text-primary-emphasis text-center d-flex  align-items-center'} imgUrl={ProjectIcon}/>
                                </div></td>
                                <td className={`${res.active === false ? 'bg-light' : ''}`}>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <CustomSwitcher active={res?.active} onChange={() => {
                                            if (res?.active === true) {
                                                deleteUser(softDeleteUserApiContract(res?.id)).then(()=>{

                                                }).catch((err)=>{
                                                    if(err.response.status !== 403){
                                                        toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})

                                                    }
                                                });
                                            } else {
                                                deleteUser(undoSoftDeleteUserApiContract(res?.id)).then(()=>{

                                                }).catch((err)=>{
                                                    if(err.response.status !== 403){
                                                        toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})

                                                    }
                                                });;
                                            }
                                        }}/>
                                    </div>
                                </td>
                                <td className={`${res.active === false ? 'bg-light' : ''}`}>
                                    <div className={'border-0'}><DropdownButton
                                        // drop={`start`}
                                        title={<img src={menu}  onClick={() => ''}/>}
                                        className={`text-white`} style={{height:'30px'}}>
                                        <DropdownItem className={``} onClick={() => {
                                            handleEdit(res);
                                        }}><span className={`bi bi-pencil me-2`}/>Edit</DropdownItem>
                                        <DropdownItem className={`text-danger`}
                                                      onClick={() => {
                                                          setTargetUser(res.id);
                                                          setShowDelete(true)
                                                      }}><span
                                            className={`bi bi-trash me-2 text-danger`}/> Delete</DropdownItem>
                                    </DropdownButton></div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>}
        </div>
        <AddEditUserModal show={showEditor} onCancel={() => {
            setShowEditor(false);
            setTargetUser(undefined);
        }} onSuccess={() => {
            setShowEditor(false);
            fetchUsers(getUserApiContract());
        }}
                          defaultValue={targetUser}
        />
        {/*<ResetPasswordModal id={targetUser} show={showPasswordEditor} onSuccess={() => {
            setShowPasswordEditor(false);
        }} onCancel={() => {
            setShowPasswordEditor(false);
            setTargetUser(undefined);
        }}
        />*/}

        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalElements={totalElements}
            onPageChange={(page) => fetchUsers(getUserApiContract(page, pageSize))
            }
        />

        <ConfirmationModal title={'Delete User'} message={'Check this box to confirm?'}
                           onSubmit={() => {
                               deleteUser(hardDeleteUserApiContract(targetUser)).then(()=>{
                                   toast.show({id: v4(), title: "Deleted Successfully", duration: 2000, isSuccess: true});
                               }).catch((err)=>{
                                   if(err.response.status !==403){
                                       toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false});
                                   }
                               })

                               setShowDelete(false);
                           }} onCancel={() => setShowDelete(false)} show={showDelete} btnText={'Delete'}/>
        <UserProjectsModal userName={activeUser} show={showProjectPermissions} onHide={()=> setShowProjectPermissions(false)} onShow={()=> setShowProjectPermissions(true)} onChange={()=> {
            fetchUsers(getUserApiContract());
        }}  />
    </div>
}
export default Users;