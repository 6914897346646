import sortOrder from "../../../../../../assets/icons/sort_order.svg";
import React, {Fragment, useEffect, useState} from "react";
import '../projectClients/ProjectClientListCard/projectClientListCard.scss'
import {useApiClient} from "../../../../../../network/client/UseApiClient";
import {GetAllProjectClients} from "../../../../../../services/ClientService";
import {useParams} from "react-router";
import {Client} from "../../../../../../models/Client";
import {NetworkState} from "../../../../../../network/dto/ApiContract";
import {Spinner} from "../../../../../../components/spinner/Spinner";
import {AddNewPartner} from "./addNewPartner/AddNewPartner";
import {EmptyState} from "../../../../../../components/emptyState/EmptyState";
import {ProjectPartnerListCard} from "./ProjectPartnerListCard/ProjectPartnerListCard";
import {GetAllProjectPartners} from "../../../../../../services/PartnerService";


export const ProjectPartners = () => {
    const [projectsPartners, projectsPartnersNetworkState, error, fetchProjectsPartners] = useApiClient<Client[]>();
    const params = useParams<{ projectId: string }>();
    const [activePartner, setActivePartner] = useState<Client>();
    const[showModal,setShowModal]=useState<boolean>(false);
    
    
    useEffect(() => {
        fetchProjectsPartners(GetAllProjectPartners(+(params?.projectId || 1)))

    }, [params?.projectId]);

    return <div className={'mt-1'}>
        <div className={``}>
            <div className={`d-flex`}>
                <button className={`btn btn-primary d-flex`} onClick={()=>setShowModal(true)}>
                    <img src={''} className={'uploadIcon me-1'} />
                    + New Partner
                </button>
                <div className={`ms-3 d-flex align-items-center`} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <img src={sortOrder} className={'me-2'}/>
                    <small>Sort by: Most Recent</small>
                </div>
            </div>
            {projectsPartnersNetworkState=== NetworkState.Loading?<div className={`w-100 d-flex justify-content-center`}>
                    <Spinner/>
                </div>:
            <div className={'clients mt-4'}>
                {projectsPartners?.length  ? <Fragment>
                {projectsPartners?.map((data: Client) => <ProjectPartnerListCard key={data.id} projectId={params.projectId||''} data={data} onDelete={()=>{
                    fetchProjectsPartners(GetAllProjectPartners(+(params?.projectId || 1)))
                }} isActive={activePartner?.id === data.id} onClick={()=> {
                    if (activePartner?.id === data.id) {
                        setActivePartner(undefined)
                    } else
                        setActivePartner(data)
                }} onSuccess={()=>{
                    fetchProjectsPartners(GetAllProjectPartners(+(params?.projectId || 1)))

                }
                }/>)}</Fragment> : <div className={'container bg-white h-100'}><EmptyState/></div>}
            </div>}
        </div>
        <AddNewPartner show={showModal} onCancel={()=>setShowModal(false)} onSuccess={()=> {
            setShowModal(false);
            fetchProjectsPartners(GetAllProjectPartners(+(params?.projectId || 1)))
        }}/>
    </div>
}