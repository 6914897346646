import {useState} from "react";
import {ApprovalRequest} from "../../../models/ApprovalRequest";
import moment from "moment";

export const UserRequestCard = (props: { data: ApprovalRequest, onApprove: (id: number) => void, onDelete: (id: number) => void }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const getAvatar = (status :string) => {
        switch (status) {
            case "APPROVED":
                return 'avatarApproved';
            case "REJECTED":
                return 'avatarRejected';
        }
    }
    return <div className={`shadow-sm rounded px-3 py-2 mt-3`}>
        <div className={`d-flex align-items-center cursor-pointer`} onClick={() => setExpanded(expanded => !expanded)}>
            <span className={`bi bi-dot text-danger`}/>
            <div className={`avatar me-2 ${getAvatar(props.data.status)}`}></div>
            <span className={`small`}>{props?.data?.createdBy.fullname}</span>
            <b className={`flex-fill m-0 ms-3 small`}>{props?.data?.title}</b>
            <span className={`small text-secondary`}>{moment(props.data.createdAt).format('DD MMM YY | hh:mm A')}</span>
        </div>
        <div className={`pt-3 pb-1 ps-3 ${expanded ? 'd-block' : 'd-none'}`}>
            <button className={`btn btn-sm btn-outline-primary me-2`} onClick={(evt) => {
                evt.stopPropagation();
                props.onDelete(props.data.id);
            }} disabled={props.data.status==='REJECTED'}>Reject
            </button>
            <button className={`btn btn-sm btn-primary`} onClick={(evt) => {
                evt.stopPropagation();
                props.onApprove(props.data.id);
            }} disabled={props.data.status==='APPROVED'}>Accept
            </button>
        </div>
    </div>
};