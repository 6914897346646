import "./appNotifications.scss";
import {UserRequestCard} from "./card/UserRequestCard";
import {useApiClient} from "../../network/client/UseApiClient";
import {ApproveRequest, FetchApprovalRequests, RejectRequest} from "../../services/ApprovalRequestService";
import {useEffect} from "react";
import {ApprovalRequest} from "../../models/ApprovalRequest";
import {NetworkState} from "../../network/dto/ApiContract";
import {Spinner} from "../spinner/Spinner";
import {useToast} from "../toast/ToastManager";
import {v4} from "uuid";

export const AppNotifications = (props: {onHide: ()=> void}) => {
    const [requests, requestsState, requestApiError, fetchRequests] = useApiClient<ApprovalRequest[]>();
    const [approved, approveRequestState, approveRequestApiError, approveRequest] = useApiClient<void>();
    const [deleted, deleteRequestState, deleteRequestApiError, deleteRequest] = useApiClient<void>();
    const toastManager = useToast();

    useEffect(()=> {
        getRequests();
    }, []);

    const getRequests = () => {
        fetchRequests(FetchApprovalRequests()).catch(err=> {
            if (err.response.status !== 403)
                toastManager.show({id: v4(), title: `Error: ${err.toString()}`, duration: 5000, isSuccess: false})
        })
    }

    const handleDelete = (requestId: number) => {
        deleteRequest(RejectRequest(requestId)).then(res=> {
            toastManager.show({id: v4(), title: 'Request got deleted successfully.', isSuccess: true, duration: 4000})
            getRequests();
        }).catch(err=> {
            if (err.response.status !== 403)
                toastManager.show({id: v4(), title: 'Failed to delete request', isSuccess: false, duration: 4000})
        })
    }
    const handleApproval = (requestId: number) => {
        approveRequest(ApproveRequest(requestId)).then(res=> {
            toastManager.show({id: v4(), title: 'Request got approved successfully.', isSuccess: true, duration: 4000});
            getRequests();
        }).catch(err=> {
            if (err.response.status !== 403)
                toastManager.show({id: v4(), title: 'Failed to approve request', isSuccess: false, duration: 4000})
        });
    }
    return <div className={`appNotificationContainer`}>
        <div className={`d-flex justify-content-between align-items-center mb-3`}>
            <b>Requests {requestsState===NetworkState.Loaded?`(${requests?.length||0})`:null}</b>
            <div><span className={`bi bi-x-lg cursor-pointer`} onClick={()=> props.onHide()}/></div>
        </div>
        <div>
            <b className={`small text-secondary`}>{(requestsState === NetworkState.Loaded && ((requests?.length||0) ===0))?'There are no notifications':requestApiError && requestApiError.response?.status===403?"You don't have permission to view Requests":null}</b>
            {requestsState===NetworkState.Loaded?requests?.map(request=> <UserRequestCard data={request} onDelete={handleDelete} onApprove={handleApproval} />):requestsState===NetworkState.Loading?<div className={`d-flex justify-content-center align-items-center`}>
                <Spinner />
            </div>:null}

        </div>
    </div>;
}