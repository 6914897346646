export const useFileUtils = () => {

    const downloadFile = (filename: string, data: Blob) => {
        const href = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();


        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    return {downloadFile};
}