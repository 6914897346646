import {ChangeEvent, useState} from "react";

export const useInputHandler = <T> (initialState: T) => {
    const [payload, setPayload] = useState<T>(initialState);

    const handleChange = (event: ChangeEvent<HTMLElement>) => {
        if (event.target instanceof HTMLInputElement) {
            if (event.target.type === 'radio') {
                updatePayload(event.target.name as keyof T, event.target.value);
            } else if (event.target.type === 'checkbox') {
                updatePayload(event.target.name as keyof T, event.target.value);
            } else {
                updatePayload(event.target.name as keyof T, event.target.value);
            }
        } else if (event.target instanceof HTMLTextAreaElement) {
            updatePayload(event.target.name as keyof T, event.target.value);
        } else if (event.target instanceof HTMLSelectElement) {
            updatePayload(event.target.name as keyof T, event.target.value);
        } else {
            updatePayload(event.target.id as keyof T, null);
        }
    }

    const setValue = (key: keyof T, value: any) => {
        updatePayload(key, value);
    }
    const setData = (data: any) => {
        setPayload(data)
    }

    const updatePayload = (key: keyof T, value: any) => {
        setPayload(prevState=> ({...prevState, [key]: value}));
    }

    return {payload, handleChange, setValue, setData}
}