import {ApiContract} from "../network/dto/ApiContract";

const GetAllProjectTeamMembers = (id:number): ApiContract => {
    return {url: `/project/${id}/team-members`, method: "GET"}
}
const GetSingleMember =(id:string):ApiContract=>{
    return {url:`/team-member/${id}`, method:'GET'}
}
const AddTeamMember=(id:string,name?:string,email?:string):ApiContract=>{
    const formData=new FormData();
    formData.append('name',name ||'');
    formData.append('email',email || '');
    return {url:`/project/${id}/team-member`,method:'POST',requestBody:formData}
}

const updateTeamMember=(id?:string,name?:string,email?:string):ApiContract=>{
    const formData=new FormData();
    formData.append('name',name ||'');
    formData.append('email',email || '');
    return {url:`/team-member/${id}`,method:'PUT',requestBody:formData}
}

const RemoveMember=(id?:string):ApiContract=>{

    return {url:`/team-member/${id}`,method:'DELETE'}
}


const DeleteChatSummary=(chatSummaryId?:string):ApiContract=>{
    return {url:`/chat_summary/${chatSummaryId}`,method:'DELETE'}
}

const getTeamMemberProjectDocuments = (projectId: string, teamMemberId: string) : ApiContract => {
    return {url : `/api/public/project/${projectId}/team-member/${teamMemberId}/documents` , method : "GET"}
}
const uploadImageTeamMember=(projectId:string,teamMemberId:string,documentType:string,file:File):ApiContract=>{
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentType',documentType)
    return {url:`/api/public/project/${projectId}/team-member/${teamMemberId}/documents/upload` ,method:"POST",requestBody:formData,config: {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }}
}

export {GetAllProjectTeamMembers,GetSingleMember,AddTeamMember,RemoveMember,DeleteChatSummary,updateTeamMember,getTeamMemberProjectDocuments,uploadImageTeamMember}