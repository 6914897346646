import React, {useEffect, useRef, useState} from "react";
import "./filesUpload.scss"
import {useApiClient} from "../../../network/client/UseApiClient";
import {UploadFile} from "../../../services/ProjectService";
import {NetworkState} from "../../../network/dto/ApiContract";
import {Spinner} from "../../spinner/Spinner";

export const UploadFiles=(props:{defaultImageUrl: string|null, onDrop?:(value:boolean)=>void, onDone: (data: any)=> void,
    onFileChange?: (file: File | null) => void})=>{
    const [images, imagesNetworkState, error, postImages] = useApiClient<any>();
    const imageChooser=useRef<HTMLInputElement>(null);
    const[dragEnter,setDragEnter]=useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(props.defaultImageUrl);
    const [uploading, setUploading] = useState<boolean>(false);const imagePicker = useRef<HTMLInputElement>(null);

    useEffect(()=> {
        if (props.defaultImageUrl && imageUrl==null) {
            setImageUrl(props.defaultImageUrl);
        }
    }, [props.defaultImageUrl])

    useEffect(()=> {
        if (imagesNetworkState === NetworkState.Loaded) {
            setUploading(false);
        }
    }, [imagesNetworkState])

    const handleDragEvent = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    }

    const handleFileChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        const selectedFiles=e.target.files;
        if (selectedFiles && selectedFiles.length > 0)
        {
            setFile(selectedFiles[0])
            setImageUrl(URL.createObjectURL(selectedFiles[0]))
            props.onFileChange && props.onFileChange(selectedFiles[0])
            uploadFiles(selectedFiles[0]);
        }

    }
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if(e.dataTransfer.files && e.dataTransfer.files.length>0)
        {
            setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]))
            setFile(e.dataTransfer.files[0])
            uploadFiles(e.dataTransfer.files[0])
            e.dataTransfer.clearData();
            setDragEnter(false);
        }
    }
    const handleDragEnter=(e: React.DragEvent<HTMLDivElement>)=>{
        handleDragEvent(e)
    }
    const handleDragLeave=(e:React.DragEvent<HTMLDivElement>)=>{
        setDragEnter(false);
    }


    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        handleDragEvent(e)
    }
    const uploadFiles=(file:File|null)=>{
        if(!file){
            alert('Please select file to upload');
            return;
        }
        else{
            setUploading(true)
            postImages(UploadFile(file!)).then(res=> {
                props.onDone(res);
            })
        }
    }

    return(
        <div className={`imageUploadWrapper col position-relative`}>
            {!imageUrl?<div className={`d-flex align-items-center justify-content-center flex-column  imageContainer py-2 `} style={{borderColor:dragEnter?"blue":"#ccc"}}
                       onClick={()=>imageChooser?.current?.click()}  onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
                <div>
                    <i className="bi bi-file-earmark-image fs-3"></i>
                </div>
                <div className={'mt-1 d-flex flex-column'}>
                    <span className={`droptext`}>Drop files here</span>
                    <span className={`text-center`}>OR</span>
                </div>
                <div>
                    <button type={`button`} className={' btn btn-sm btn-secondary m-1'}>Browse Images</button>
                </div>
            </div>:<div className={`position-relative`}>
                <span className={`bi bi-x-lg closeBtn`} onClick={()=> {
                    setFile(null);
                    props.onDone(null);
                    setImageUrl(null);
                    if (imageChooser?.current?.value)
                        imageChooser!!.current!!.value = '';
                }} />
                <img src={imageUrl} className={`previewImage`} />
            </div>}
            {
                uploading?<div className={`d-flex justify-content-center align-items-center position-absolute w-100 h-100 start-0 top-0 loadingOverlay`}>
                    <Spinner />
                </div>:null
            }
            <input type={'file'} ref={imageChooser} name={'file'} id="fileInput" multiple={false} onChange={handleFileChange} hidden />
        </div>
    )
}
export default UploadFiles;