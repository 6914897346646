import {ServerFile} from "../../models/Document";
import {PdfViewerDialog} from "../pdf/PdfViewerDialog";
import {DocViewerDialog} from "./DocViewerDialog";
import {memo, useEffect, useMemo, useState} from "react";
import {ImageViewer} from "../viewer/ImageViewer";

export const DocView = (props: {file?: ServerFile, onHide: ()=> void}) => {
    const [extension, setExtension] = useState<string>()
    const [document, setDocument] = useState<ServerFile>()
    useEffect(()=> {
        if (props.file) {
            const tokens = props.file?.name.split(`.`);
            const ext = tokens[tokens.length-1];
            if (ext !== extension && props.file && props.file.url) {
                setDocument(props.file)
                setExtension(ext)
            }
        } else {
            setDocument(undefined)
            setExtension(undefined)
        }
    }, [props.file, props.file?.url])
    const getView = () => {
        return extension === 'pdf'?<PdfViewerDialog onHide={props.onHide} document={document} />: (extension=== 'png' || 'jpg' || 'jpeg')?<ImageViewer onHide={props.onHide} fileName={document?.randomFileName} url={document?.url} />:<DocViewerDialog onHide={props.onHide} docUrl={document} />;
    }
    return getView()
}