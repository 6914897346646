import React, {FormEvent, Fragment, useEffect} from "react";
import {useApiClient} from "../../../../../../../network/client/UseApiClient";
import {Client} from "../../../../../../../models/Client";
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import {Button} from "../../../../../../../components/button/Button";
import {NetworkState} from "../../../../../../../network/dto/ApiContract";
import {useInputHandler} from "../../../../../../../hooks/UseInputHandler";
import {useParams} from "react-router";
import {useToast} from "../../../../../../../components/toast/ToastManager";
import {v4} from "uuid";
import {AddTeamMember, GetSingleMember, updateTeamMember} from "../../../../../../../services/TeamService";

export const AddNewTeamMember = (props: {
    show: boolean,
    onCancel: (shouldRefresh?: boolean) => void,
    onSuccess: (shouldRefresh?: boolean) => void,
    defaultValue?: string,
}) => {
    const {payload, handleChange, setValue, setData} = useInputHandler<any>({});
    const [teamMember, teamMemberNetworkState, error, postTeamMember] = useApiClient<Client[]>();
    const [teamMemberData, teamMemberDataNetworkState, errorPartner, getTeamMemberData] = useApiClient<Client[]>();
    const params = useParams<{ projectId: string }>();
    const toast = useToast();

    useEffect(() => {
        if (props.defaultValue) {
            getTeamMemberData(GetSingleMember(props.defaultValue)).then((res) => {
                setData(res)
            });
        }
    }, [props.defaultValue]);

    const submitForm = (event: FormEvent) => {
        event.preventDefault();
        if (props?.defaultValue) {
            postTeamMember(updateTeamMember(props?.defaultValue, payload.name, payload.email)).then(res => {
                props.onSuccess(true);
                toast.show({id: v4(), title: "Member Updated Successfully", duration: 2000})
            }).catch(err => {
                if (err.response.status !== 403) {
                    toast.show({id: v4(), title: "Fail to update", duration: 2000, isSuccess: false});
                }
            })
        } else {
            postTeamMember(AddTeamMember(params?.projectId || '', payload.name, payload.email)).then(res => {
                props.onSuccess(true);
                toast.show({id: v4(), title: "New Member Added Successfully", duration: 4000})
                setData('')
            }).catch(err => {
                if (err.response.status !== 403) {
                    toast.show({id: v4(), title: "Fail to add", duration: 2000, isSuccess: false});
                }
            })
        }

    }

    const onClose = () => {
        props.onCancel();
        setData({});
    }


    return <Fragment>
        <Modal show={props.show} onHide={onClose} centered style={{}} className={'modal documentModal'}>
            <Modal.Header closeButton className="border-0 pb-0">
                <h6 className={'fw-bolder'}>{props?.defaultValue ? "Update Member " : "Add New Member"}</h6>
            </Modal.Header>
            <ModalBody className="modal-content rounded-0 border-0">
                <form id={`form_member`} className={'flex-fill overflow-y-auto'} onSubmit={submitForm}>
                    <div className="form-group mt-2">
                        <label className={'small text-secondary'}><b>Name <span className='text-danger'>*</span></b></label>
                        <input type="text" className="form-control rounded-0" id="exampleInputName"
                               aria-describedby="nameHelp"
                               onChange={handleChange} value={payload.name || ''} name="name"
                               required={true}/>

                    </div>
                    <div className="form-group mt-2">
                        <label className={'small text-secondary'}><b>Email <span className='text-danger'>*</span></b></label>
                        {(props?.defaultValue) ?
                            <input type={'email'} className="form-control rounded-0" id="exampleInputName"
                                   aria-describedby="nameHelp"
                                   onChange={handleChange} value={payload.email || ''} name="email" disabled={true}/> :
                            <input type={'email'} className="form-control rounded-0" id="exampleInputName"
                                   aria-describedby="nameHelp"
                                   onChange={handleChange} value={payload.email || ''} name="email" required/>}

                    </div>
                    {/*<div className="form-group mt-2">*/}
                    {/*       <label className={'small text-secondary'}><b>Phone No.</b> (optional)</label>*/}
                    {/*       <input type={'number'} className="form-control rounded-0" id="exampleInputName"*/}
                    {/*              aria-describedby="nameHelp"*/}
                    {/*              onChange={handleChange} value={payload.phoneNumber || ''} name="phoneNumber"/>*/}

                    {/*   </div>*/}
                </form>
            </ModalBody>
            <ModalFooter className={'d-flex justify-content-end border-0'}>
                <Button type={'button'} className={'btn btn-outline-primary py-0 px-3 small fw-bold'}
                        onClick={() => props.onCancel(false)}>Cancel
                </Button>
                <Button type={'submit'} loading={teamMemberNetworkState === NetworkState.Loading} form={`form_member`}
                        loaderText={`...saving`}
                        className={'btn btn-primary py-0 px-3  small'}>{props?.defaultValue ? 'Update' : "Add"}</Button>
            </ModalFooter>
        </Modal>


    </Fragment>
}
