import {useEffect, useState} from "react";
import {Session, SessionUser} from "../models/Session";
import {useNavigate} from "react-router-dom";
import {useToast} from "./UseToast";
import {v4} from "uuid";
import moment from "moment";
import {UserProfile} from "../models/UsersModel";
import {Project} from "../models/Project";

export const KEY_ACCESS_TOKEN = 'user_token';
export const KEY_CHAT_USER = 'chat_user';
export const useSessionManager = () => {
    const KEY_SESSION_USER = 'session_user';
    const KEY_CHAT_ID = 'chat_id';
    const KEY_DEFAULT_PROJECT = 'default_project';
    const KEY_CHAT_TIMESTAMP = 'chat_timestamp';
    const KEY_IS_LOGGED_IN = 'is_logged_in';
    const [session, setSession] = useState<Session>();
    const [chatUser, putChatUser] = useState<UserProfile>();
    const [defaultProject, putDefaultProject] = useState<Project>();
    const [chatId, setUserChatId] = useState<string|null>();
    const [chatTimestamp, setChatTimestamp] = useState<any|null>();
    const navigate = useNavigate();
    const {displayToast} = useToast();

    useEffect(()=> {
        if (localStorage && localStorage.getItem(KEY_IS_LOGGED_IN) === 'true') {
            const accessToken = localStorage.getItem(KEY_ACCESS_TOKEN);
            const userJson = localStorage.getItem(KEY_SESSION_USER);
            const user = userJson && userJson !== "undefined"?JSON.parse(userJson):undefined;
            setSession({accessToken: accessToken||'', isLoggedIn: true, user: user})
            setUserChatId(localStorage.getItem(KEY_CHAT_ID));
            const json = localStorage.getItem(KEY_CHAT_USER);
            const chatSessionTime = moment(localStorage.getItem(KEY_CHAT_TIMESTAMP));
            setChatTimestamp(chatSessionTime);
            if (json) {
                putChatUser(JSON.parse(json));
            }
            const defaultProjectJson = localStorage.getItem(KEY_DEFAULT_PROJECT);
            if (defaultProjectJson) {
                putDefaultProject(JSON.parse(defaultProjectJson));
            }
        } else {
            setSession({isLoggedIn: false})
        }
    }, [localStorage])

    const createSession = (accessToken: string, user?: SessionUser) => {
        const session: Session = {
            accessToken: accessToken,
            isLoggedIn: true,
            user: user
        }
        localStorage.setItem(KEY_ACCESS_TOKEN, accessToken);
        localStorage.setItem(KEY_IS_LOGGED_IN, 'true');
        if (user) {
            localStorage.setItem(KEY_SESSION_USER, JSON.stringify(user));
        }
        setSession(session);
    }

    const clearSession = () => {
        setSession(undefined);
        navigate(0);
        displayToast({id: v4(), label: 'Logout', message: `${session?.user?.username} Logged out successfully.`});
        localStorage.removeItem(KEY_ACCESS_TOKEN);
        localStorage.removeItem(KEY_IS_LOGGED_IN);
        localStorage.removeItem(KEY_SESSION_USER);
        localStorage.removeItem(KEY_CHAT_USER);
        clearChatSession();
        localStorage.removeItem(KEY_DEFAULT_PROJECT);
    }


    const setChatId = (chatId: string) => {
       if(chatId) {
            setUserChatId(chatId);
            localStorage.setItem(KEY_CHAT_ID, chatId);
            const timestamp = moment();
            localStorage.setItem(KEY_CHAT_TIMESTAMP, timestamp.toISOString());
            setChatTimestamp(timestamp);
        }
    }

    const clearChatSession = () => {
        localStorage.removeItem(KEY_CHAT_ID);
        localStorage.removeItem(KEY_CHAT_TIMESTAMP);
    }

    const resetChatTimestamp = () => {
        localStorage.setItem(KEY_CHAT_TIMESTAMP, moment().toISOString());
    }

    const setUser = (accessToken: string, user?: any) => {
        const newSession: Session = session?{...session}:{isLoggedIn: false};
        newSession.user = user;
        localStorage.setItem(KEY_SESSION_USER, JSON.stringify(user));
        setSession(newSession);
    }

    const isChatSessionValid = (): boolean => {
        const chatSessionTime = moment(localStorage.getItem(KEY_CHAT_TIMESTAMP));
        const currentDate = moment();

        const diff = currentDate.diff(chatSessionTime, 'minutes');
        return  diff <= 2;
    }

    const setChatUser = (user: UserProfile) => {
        localStorage.setItem(KEY_CHAT_USER, JSON.stringify(user));
        putChatUser(user);
    }
    const setDefaultProject = (project: Project) => {
        localStorage.setItem(KEY_DEFAULT_PROJECT, JSON.stringify(project));
        putDefaultProject(project);
    }

    return {session, chatId, createSession, setUser, clearSession, setChatId,
        setChatUser, chatUser, isChatSessionValid, chatTimestamp, resetChatTimestamp, defaultProject, setDefaultProject, clearChatSession}
}