import {ApiContract} from "../network/dto/ApiContract";

const getClientProjectDocuments = (projectId: string, clientId: string) : ApiContract => {
    return {url : `/api/public/project/${projectId}/client/${clientId}/documents` , method : "GET"}
}
const uploadImageClient=(projectId:string,clientId:string,documentType:string,file:File):ApiContract=>{
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentType',documentType)
    return {url:`/api/public/project/${projectId}/client/${clientId}/documents/upload` ,method:"POST",requestBody:formData,config: {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }}
}

export {getClientProjectDocuments,uploadImageClient}