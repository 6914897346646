import {Fragment} from "react";
import {PartnerFileCard} from "./PartnerFileCard";

export const PartnerFiles = (props: {data: any[]}) => {
    return <Fragment>
        <div className={`d-flex justify-content-center align-items-center p-5`}>
            <big className={'text-secondary fst-italic'}>Coming Soon...</big>
        </div>
        {/*<div className={`row row-cols-3 row-cols-xl-5 w-100`}>*/}
        {/*    {props.data.length>0?props.data.map(file=> <div className={`col`}>*/}
        {/*        <PartnerFileCard fileName={file.name}/>*/}
        {/*    </div>):<label className={`mt-2 text-decoration-underline`}>No Data</label>}*/}
        {/*</div>*/}
    </Fragment>;
}