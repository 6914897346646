import {Project} from "./Project";

export type UsersModel = {
    id?: string,
    username?: string,
    fullname?: string,
    email?: string,
    password?: string,
    quivrId?: string,
    permissions?: {role: UserRole, project: Project,userGroup: UserGroup}[]
    organization?: {
        id?: string,
        name?: string,
        brainId?: string,
        email?:string,
        website?:string,
        logoImageUrl?:string
    }
    active?: boolean
    role?: UserRole
}

export type UserPasswordRequest = {
    id?: string,
    password?: string,
    confirmPassword?: string
}

export interface UserProfile {
    quivrId: string,
    user_name: string,
    brainId: string,
    role: UserRole
}

export enum UserRole {
    Admin = "Admin", SuperAdmin = "Super_Admin", Member = "Member", Unknown= 'Unknown'
}
export enum UserGroup {
    Team_Member = "TEAM_MEMBER", Owner = "OWNER", Partner = "PARTNER"
}