import {Modal} from "react-bootstrap";
import {useApiClient} from "../../network/client/UseApiClient";
import "./drawing_dialog.scss";
import {ForgeViewer} from "../autodesk/ForgeViewer";
import {Drawing} from "../../models/Drawing";
import {useEffect} from "react";
import {getDocumentDetails} from "../../services/DocumentService";

export const DrawingDialog = (props: {data?: any, onHide: ()=> void, drawing?: Drawing})=> {
    const [docDetails, downloadDocNetworkState, downloadError, downloadDocs, docRes] = useApiClient<any>();

    useEffect(()=> {
        if (props.drawing) {
            downloadDocs(getDocumentDetails(props.drawing.documentId))
        }
    }, [props.drawing])

    return <Modal show={!!props.data} onHide={props.onHide} style={{zIndex: '9999'}} size={'xl'} className={`dd`}>

            <div className={`drawingDialog`}>
                {/*<div className={`d-flex align-items-center mb-2`}>
                    <button className={`btn btn-close-dialog cursor-pointer`} onClick={()=> props.onHide()}><span className={`bi bi-x-lg`} /> </button>
                    <h6 className={`text-white m-0`}>{docDetails?.name || ''}</h6>
                </div>*/}
                <div>
                    <div className={`modalBody row g-0`}>
                        <div className={`col-7`}>
                            <div className={`mainPanel`}>
                                <div className={`drawingContainer`}>
                                    {docDetails?<ForgeViewer docDetails={docDetails} highlight={props.drawing?.objectId || 0} />:null}
                                </div>
                            </div>
                        </div>
                        <div className={`col-5 sidePanel`}>
                            <div className={`p-1`}>
                                <div className={'d-flex justify-content-between'}><label>Name: {docDetails?.name || ''}</label><br/> <span className={'bi bi-x-lg cursor-pointer'} onClick={()=> props.onHide()} /></div>
                                <label>Status: {docDetails?.indexStatus || '' }</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Modal>
}