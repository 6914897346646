import {Fragment} from "react";
import './ClientLink.scss';
import linkIcon from '../../../assets/icons/linkIcon.svg';
export const ClientLinks = (props: {data: any[]}) => {
    return <Fragment>
        <div className={''}>
            {props.data.length>0?props.data.map(link=>
                    <div className={`border-1 mb-1`}><div className={'d-flex justify-content-start align-items-center clintLinkCard text-primary text-decoration-underline bg-body'}><span className={'ms-2'}><img src={linkIcon} className={'me-2'}/><a href={link.link} target={'_blank'}>{link.link}</a></span></div></div>
                ):
                <label className={`mt-2 text-decoration-underline`}>No Data</label>}
        </div>

    </Fragment>
}