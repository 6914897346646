import searchIcon from "../../assets/icons/search.svg";
import "./searchbar.styles.scss";

export const Searchbar = () => {
    return <div className={`input-group input-group-sm searchbar align-self-center col`} style={{ pointerEvents: 'none', opacity: 0.5 }}>
        <input type="search" className="form-control ps-3 text-center shadow-sm"
               placeholder="Search projects, client, or items" aria-label="Recipient's username"
               aria-describedby="basic-addon2"/>
        <span className="input-group-text p-1 border-start-0 bg-white pe-3 shadow-sm" id="basic-addon2"><span
            className={`searchIcon`}><img src={searchIcon}/></span></span>
    </div>
}