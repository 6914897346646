import {createContext, useContext, useState} from "react";
import './toast.styles.scss';
import {ToastCard} from "./ToastCard";

const ToastContext = createContext<{messages: Map<string, ToastMessage>, setMessages?: (messages: Map<string, ToastMessage>) => void}>({messages: new Map()});
export const ToastProvider = (props: {children: any})=> {
    const [messages, setMessages] = useState(new Map<string, ToastMessage>());
    const removeMessage = (id: string) => {
        setMessages(prevState => {
            const toasts = new Map(prevState);
            toasts.delete(id);
            return toasts;
        })

    }
    
    return <ToastContext.Provider value={{messages: messages, setMessages: setMessages}}>
        {props.children}
        <div className={`toastContainer`}>
            <div className={`toastBar`}>
                {(Array.from(messages.values())).map(message=> (<ToastCard key={message.id} message={message} onHide={removeMessage} />))}
            </div>
        </div>
    </ToastContext.Provider>
}
export interface ToastMessage {
    id: string,
    label: string,
    message: string
}
export const useToast = ()=> {
    const toastState = useContext(ToastContext);

    const displayToast = (message: ToastMessage) => {
        const toasts = new Map(toastState.messages);
        toasts.set(message.id, message);
        if (toastState.setMessages)
            toastState.setMessages(toasts);
    }
    const removeMessage = (id: string) => {
        const toasts = new Map(toastState.messages);
        toasts.delete(id);
        if (toastState.setMessages)
            toastState.setMessages(toasts);
    }
    return {displayToast}
}