import {TabMenuItem} from "../tab/TabMenuItem";
import './clientDocs.styles.scss';
import {ReactElement, useEffect, useState} from "react";
import {useApiClient} from "../../network/client/UseApiClient";
import {getClientProjectDocuments} from "../../services/ClientProjectService";
import {ClientImages} from "./images/ClientImages";
import {ClientLinks} from "./links/ClientLinks";
import {ClientChatSummaries} from "./chat/ClientChatSummaries";
import {ClientFiles} from "./files/ClientFiles";


export const ClientDocs = (props: {projectId: string, clientId: string}) => {
    const [activeTab, setActiveTab] = useState<string>('summary');
    const [clientProjectDocs, cpDocNetworkState, cpDocError, fetchClientProjectDocuments] = useApiClient<any>();

    useEffect(() => {
        fetchClientProjectDocuments(getClientProjectDocuments(props.projectId, props.clientId))
    }, []);

    const getData = (type: string): any[] => {
        switch (type) {
            case 'summary':
                return clientProjectDocs?.data.chatSummaries||[];
            case 'images':
                return clientProjectDocs?.data.images||[];
            case 'links':
                return clientProjectDocs?.data.links||[];
            case 'files':
                return clientProjectDocs?.data.documents||[];
            default:
                return []
        }
    }

    const renderLayout = (type: string, data: any[]): ReactElement => {
        switch (type) {
            case 'summary':
                return renderSummary(data);
            case 'images':
                return renderImages(data);
            case 'links':
                return renderLinks(data);
            case 'files':
                return renderFiles(data);
            default:
                return <div></div>
        }
    }

    const renderSummary = (data: any[]) => {
        return <ClientChatSummaries data={data} />
    }
    const renderLinks = (data: any[]) => {
        return <ClientLinks data={data} />
    }

    const renderImages = (data: any[]) => {
        return <ClientImages data={data} clientId={props.clientId}  projectId={props.projectId} onSuccess={()=>
            fetchClientProjectDocuments(getClientProjectDocuments(props.projectId, props.clientId))

        }/>
    }
    const renderFiles = (data: any[]) => {
        return <ClientFiles data={data} />
    }

    return <div className={'card-box shadow-sm clientDocumentCard'}>

        <div>
            <div className={``}><p>Documents</p></div>
            <div className={` d-flex justify-content-start mt-0`}>
                <nav className="nav flex-column flex-sm-row nav-tabs my-3">
                    <TabMenuItem label={'Chat summary'}
                                 isActive={activeTab==='summary'} onClick={()=> setActiveTab('summary')}/>
                    <TabMenuItem label={'Images'}
                                 isActive={activeTab==='images'} onClick={()=> setActiveTab('images')}/>
                    <TabMenuItem label={'Links'}
                                 isActive={activeTab==='links'} onClick={()=> setActiveTab('links')}/>
                    <TabMenuItem label={'Files'}
                                 isActive={activeTab==='files'} onClick={()=> setActiveTab('files')}/>
                </nav>
            </div>
            <div>
                {
                    renderLayout(activeTab, getData(activeTab))
                }
            </div>
        </div>
    </div>
}