import {Form} from "react-bootstrap";
import React, {useState} from "react";
import './CustomSwitcher.scss'

export type CustomSwitcherData = {
    active?: boolean,
    activeLabel?: string,
    inactiveLabel?: string,
    onChange: () => void
}

const CustomSwitcher = (props: CustomSwitcherData) => {
    const [active, setActive] = useState(props.active);

    return <div className={'ps-5'}>
        <Form>
            <Form.Check
                className={'ps-0 d-flex align-items-center '}
                type="switch"
                label={active ? props?.activeLabel : ''}
                defaultChecked={active}
                id="custom-switch"
                onClick={() => {
                    setActive(!active);
                    props.onChange();
                }}
            />

        </Form>
    </div>
}

export default CustomSwitcher;