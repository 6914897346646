import React, {useEffect} from "react";
import {Drawing} from "../../../models/Drawing";
import {useApiClient} from "../../../network/client/UseApiClient";
import {ServerFile} from "../../../models/Document";
import {getDocumentDetails} from "../../../services/DocumentService";
import pdfIcon from '../../../assets/icons/pdf.png';

export const DocCard = (props: {drawing: Drawing, onClick: (evt?: ServerFile)=> void}) => {
    const [document, documentState, docError, fetchDocDetails] = useApiClient<ServerFile>();
    useEffect(() => {
        fetchDocDetails(getDocumentDetails(props.drawing.documentId || ''));
    }, [])
    return <div className={`drawingCard`}>
        <div className={`header`} onClick={()=> props.onClick(document)}>
            <img className={`icon`} src={pdfIcon}/>
        </div>
        <div className={`body`}>
            <small>{document?.name || document?.randomFileName}</small>
            {/*<button className={`btn`}><span className={`bi bi-share`}></span></button>*/}
        </div>
    </div>;
}