import {Modal, ModalBody} from "react-bootstrap";
import imgInit from '../../assets/images/screenshot_1.png';
import img from '../../assets/images/screenshot_2.png';

export const SetDefaultProjectGuideModal = (props: { show: boolean, onHide: () => void }) => {
    return <Modal show={props.show} onHide={props.onHide} >
        <ModalBody>
            <h6 className={`text-center`}>Please Select a project first as Default to start a chat conversation or select a project from project
                listing page.</h6>
            <div className={`d-flex mt-3`}>
                <div>
                    <img src={imgInit} style={{width: '100%', height: '220px', objectFit: 'contain'}}/>
                </div>
                <span className={`align-self-center text-danger`}>&gt;</span>
                <div>
                    <img src={img} style={{width: '100%', height: '220px', objectFit: 'contain'}}/>
                </div>
            </div>
        </ModalBody>
    </Modal>;
}