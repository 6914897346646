export interface InitiateChatRequest {
    name: string,
    user_id: string
}

export interface ChatInitiationResponse {
    chat_id: string
}

export interface AskQuestionRequest {
    model: string;
    question: string;
    temperature: number;
    max_tokens: number;
}

export interface QuestionResponse {
    assistant: string,
    token_limit?: TokenLimitStatus,
    context_docs: ReferenceDoc[]
}

export interface ReferenceDoc {
    content: string,
    page: number,
    documentId: string,
    objectId: number,
    fileType: string
}

export enum TokenLimitStatus {
    NOT_EXCEEDED= 'Not Exceeded', EXCEEDED = 'Exceeded'
}