import {ApiContract} from "../network/dto/ApiContract";

const AddPermissions = (projectId:string, role:string,userGroup:string, userId:string): ApiContract => {
    return {url: `/project/permissions`, method: "POST",requestBody : {
            role: role,
            userGroup:userGroup,
            userId: userId
        },config:{
         params:{
             projectId:projectId,
         }
        }}
}
const UpdatePermissions = (projectId:string,role:string,userGroup:string,userId:string): ApiContract =>{
    return {url:`/project/permissions`, method:'PUT',config : {
            params :{
                projectId:projectId,
                role: role,
                group:userGroup,
                userId: userId
            }
        }}
}
const DeletePermissions = (projectId:string,userId:string): ApiContract =>{
    return {url:`/project/${projectId}/permissions`, method:'DELETE',config:{
        params:{
            userId: userId
        }
        }}
}


export {AddPermissions, UpdatePermissions,DeletePermissions}