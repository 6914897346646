import {Fragment, useState} from "react";
import {DataValue} from "./DataValue";
import {Collapse} from "react-bootstrap";

export const ModelResultRow = (props: {label: string, dataset: any[]}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    return <Fragment>
        <tr className={expanded?'active':''}>
            <td>
                <button className={`btn p-0 rounded-0 m-0`} onClick={()=> {
                    setExpanded((expanded) => !expanded);
                }}><span className={`bi ${expanded?'bi-dash':'bi-plus'} fs-6`} /></button>
            </td>
            <td>
                {props.label}
            </td>
            <td>
                {props.dataset.length}
            </td>
        </tr>
        <tr >
            <td className={`innerRow p-0 bg-light`} colSpan={3}>
                <Collapse in={expanded}>
                    <div className={`overflow-hidden`}>
                        <div className={`p-1`}>
                            <div className={`d-flex mb-2`}>
                                <DataValue label={`Pass`} value={`${props.dataset.filter(item=> item.status.toLowerCase()==='ok').length}`} className={`me-3`} />
                                <DataValue label={`Fail`} value={`${props.dataset.filter(item=> item.status.toLowerCase()!=='ok').length}`} />
                            </div>
                            {/*<hr/>*/}
                            {/*<label className={`text-decoration-underline small`}>Details</label>*/}
                            <div style={{maxHeight: '400px'}} className={`overflow-auto`}>
                                {props.dataset.length >0?<table className={`table table-sm table-striped overflow-auto table-light innerTable`} >
                                    <thead className={`sticky-top top-0`}>
                                    <tr>
                                        {Object.keys(props.dataset[0]).map(key=> <th>{key}</th>)}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.dataset.map(row=> <tr>{Object.values(row).map((value: any)=> <td className={`small`}>{value}</td>)}</tr>)}
                                    </tbody>
                                </table>:<div>No test results found</div>}
                            </div>
                        </div>
                    </div>
                </Collapse>
            </td>
        </tr>
    </Fragment>
}