import React, {useState} from 'react';
import './projectCard.scss';
import {Project} from "../../../../../models/Project";
import {DropdownButton, DropdownItem} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useApiClient} from "../../../../../network/client/UseApiClient";
import {DeleteProjectApiContract} from "../../../../../services/ProjectService";
import ConfirmationModal from "../../../../../components/confirmationDialog/ConfirmationModal";
import {useSessionManager} from "../../../../../hooks/UseSessionManager";
import Joyride, {CallBackProps, STATUS} from "react-joyride";
import { UserRole, UsersModel } from '../../../../../models/UsersModel';

interface ProjectCardProps {
    data?: Project;
    onClick: () => void
    onDelete: () => void;
    isDefault: boolean;
    onSetDefault: () => void,
    idx: number
}

const ProjectGridCard: React.FC<ProjectCardProps> = ({data, onClick, onDelete, isDefault, onSetDefault, idx}) => {
    const [user] = useApiClient<UsersModel>();
    const {chatUser} = useSessionManager();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [project, projectNetworkState, error, deleteProject] = useApiClient<Project>();
    const navigate = useNavigate();
    const [guideSteps, setGuideSteps] = useState<any[]>([{
        target: '.guide-point-1',
        content: 'Click "Set as Default" or Click on project card to initialize chat.',
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,

    }]);
    const [runRide, setRunRide] = useState<boolean>(true);
    const [stepIndex, setStepIndex] = useState<number>(0);
    const [canShowGuide, setCanShowGuide] = useState<boolean>(localStorage.getItem("guideCompleted")!=="true");

    const removeProject = (id?: string) => {
        deleteProject(DeleteProjectApiContract(id)).then(() => {
            onDelete()
        })
    }

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type, action } = data;
        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) || action === 'close') {
            setRunRide(false);
        }
        console.log(`type: ${type}`)
        console.log(`status: ${status}`)
    };
    return (
        <div className={`col`}>
            <div className={`card w-100 overflow-hidden h-100 ${isDefault? 'default' : ''}`} onClick={() => {
                onClick()
            }}>
                {data?.profilePicture && data.profilePicture.trim() !== ''?<img src={data?.profilePicture} style={{height: '100%', position: 'absolute', width: '100%', backgroundColor: "white"}}/>:null}
                <div className={'d-flex justify-content-between'}>
                    <div><label>{data?.name}</label></div>
                    <div onClick={(evt) => {
                        evt.stopPropagation();
                    }} className={`mt-3`}><DropdownButton
                        onToggle={nextShow => {
                            if (nextShow) {
                                setRunRide(false);
                                setCanShowGuide(false);
                                localStorage.setItem("guideCompleted", "true")
                            }
                        }}
                        drop={`end`}
                        title={<div style={{backgroundColor: '#0008', height: '28px', width: '28px'}} className={`d-flex align-items-center justify-content-center rounded border`}><span onClick={(evt) => ''}
                                          className={'text-white bi bi-three-dots-vertical'}/></div>}
                        className={`text-white bg-transparent me-2 ${idx==0?'guide-point-1':''}`}>
                        <DropdownItem className={``} onClick={(evt) => {
                            evt.stopPropagation();
                            navigate(`/manage/project/edit-project/${data?.id}`)
                        }}><span className={`bi bi-pencil me-2`}/>Edit</DropdownItem>
                        <DropdownItem className={`set-default-project`} onClick={(evt) => {
                            evt.stopPropagation();
                            onSetDefault();
                        }}><span className={`bi bi-check-circle me-2 `}/> Set as default</DropdownItem>
                        {( chatUser?.role === UserRole.SuperAdmin &&
                        <DropdownItem className={`text-danger`} onClick={(evt) => {
                            evt.stopPropagation();
                            setShowConfirmationDialog(true)
                        }}><span className={`bi bi-trash me-2 text-danger`}/> Delete</DropdownItem>
                      )}
                    </DropdownButton>
                    </div>

                </div>
                <div className={`overlay`}/>
            </div>
            <ConfirmationModal title={'Delete Project'} message={'Are you sure you want to delete this Project?'}
                               onSubmit={() => {
                                   removeProject(data?.id);
                                   setShowConfirmationDialog(false)
                               }} onCancel={() => setShowConfirmationDialog(false)} show={showConfirmationDialog}
                               btnText={'Delete'}/>
            {idx===0 && canShowGuide?<Joyride steps={guideSteps} run={runRide} continuous showSkipButton callback={handleJoyrideCallback}
                              scrollToFirstStep
                              showProgress
                              styles={{
                                  options: {
                                      zIndex: 1000,
                                  }
                              }}
                                              disableScrolling
                              stepIndex={stepIndex}
            />:null}
        </div>
    );
}

export default ProjectGridCard;