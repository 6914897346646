import "./projectCardList.scss";
import React, {Fragment, useContext, useEffect, useState} from 'react';
import ProjectGridCard from "./cards/ProjectGridCard";
import {Project} from "../../../../models/Project";
import ProjectListCard from "./cards/ProjectListCard";
import {useNavigate} from "react-router-dom";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import {DefaultProjectContext} from "../../../../hooks/DefaultProject";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

interface ProjectCardListProps {
    projects: Project[]
    viewType: 'GRID'|'LIST'
}

const Projects: React.FC<ProjectCardListProps> = ({viewType, projects}) => {

    const dps = useContext(DefaultProjectContext);

    // const projectData: Project = {id: 'dp', name: 'Project Name', owner: '', status:'InProgress', dueDate: '8 Nov 2023', notes: 'This is testing Project', updatedDate: '03 Oct 2023'};
    // const projectData2: Project = {id: 'dp', name: 'This is project Name', owner: 'KD Font', status:'InProgress', dueDate: '8 Nov 2023', notes: 'This is testing Project', updatedDate: '03 Oct 2023'};
    const navigate = useNavigate();

    const handleClickAction = (id: string) => {
        navigate(`project/${id}/documents`);
    }
    return <Fragment>
        {
            viewType === 'GRID' ? <div className="row row-cols-4 g-4 projects step-1" key={dps.defaultProject?.id}>
                {/*{*/}
                {/*projects?.length > 0 ?*/}
                <Fragment>
                    {projects.map((project, idx)=> <ProjectGridCard isDefault={dps.defaultProject?.id === project.id} key={project.id} data={project} idx={idx} onClick={()=> {handleClickAction(project?.id  || '')}} onDelete={()=>project} onSetDefault={()=> {
                        if (dps.setDefaultProject) {
                            dps.setDefaultProject(project);
                        }}}/>) }
                </Fragment>
                {/*    : <div className={'container bg-white h-100'}><EmptyState/></div>*/}
                {/*}*/}

            </div> : <div className={`projects step-1`} id={`projects`}>
                {
                    projects?.length > 0 ?
                        <table className={`text-center w-100 border-0`}>
                            <thead>
                            <td><small>Project</small></td>
                            <td><small>Owner</small></td>
                            <td><small>Dates</small></td>
                            <td><small>Status</small></td>
                            <td><small>Notes</small></td>
                            <td><small>Priority</small></td>
                            <td><small>Last Updated</small></td>
                            <td></td>
                            </thead>

                            <tbody>
                            {projects.map((project, idx)=> <ProjectListCard isDefault={dps.project?.id === project.id} data={project} idx={idx} key={project.id} onClick={()=> {handleClickAction(project?.id  || '')}} onDelete={()=>project}/>)}
                            </tbody>
                        </table> :
                        <div className={'container h-100 bg-white'}><EmptyState/></div>

                }
            </div>
        }
    </Fragment>;
};

export default Projects;