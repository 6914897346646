import 'react-pdf/dist/Page/AnnotationLayer.css';
import {Document, Page, pdfjs} from 'react-pdf';
import React, {ChangeEvent, useCallback, useState} from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './textLayer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function highlightPattern(text: string, pattern?: string) {

    // console.error(text)
    if (pattern) {
        /*let lines = pattern.split('\n');
        lines = lines.flatMap((line) =>
            line.split('.').join('.$/').split(`$/`)
        );*/

        let converted = text;
        /*lines
            .filter((line) => line.trim() !== '')
            .forEach((line) => {
                if (converted.includes(line.trim())) {
                    converted = `<mark>${converted}</mark>`
                }
            });*/
        console.error(converted)
        pattern = pattern.replaceAll("  ", " ")
        if (converted.trim() !== '' && pattern.includes(converted)) {
            converted = `<mark>${converted}</mark>`
        }
        return converted;
    } else {
        return text;
    }
}


export const PdfRenderer = (props: { url: string, highlight?: string, page?: number, className?: string }) => {
    const [searchText, setSearchText] = useState<string | undefined>(
        props.highlight
    );
    const [page, setPage] = useState<number>(props.page || 1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    let jumpToPage: number | undefined = props.page || 1;

    const textRenderer = useCallback(
        (textItem: any) => highlightPattern(textItem.str, searchText),
        [searchText]
    );


    const handlePageSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setPageSize(parseInt(e.target.value));
        setPage(1); // Reset page to 1 when changing page size
    };
    const goRight = (offset: number) => {
        if (page < totalPages) {
            setPage(page + offset);
        }
    };
    const goLeft = (offset: number) => {
        if (page > 1) setPage(page - offset);
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 1 && value <= totalPages) {
            jumpToPage = value;
        } else
            jumpToPage = undefined;
    };

    const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (jumpToPage && jumpToPage >= 1 && jumpToPage <= totalPages) {
            setPage(jumpToPage);
        }
    };

    return (
        <div className={`${props.className} d-flex flex-column overflow-hidden border-top`}>
            <div style={{maxWidth: '100%', overflow: 'auto'}} className={`flex-fill overflow-auto position-relative`}>
                <Document
                    file={{url: props.url}}
                    onLoadSuccess={(document) => {
                        setTotalPages(document.numPages);
                    }}>
                    <Page
                        renderAnnotationLayer={true}
                        customTextRenderer={textRenderer}
                        pageNumber={page || 1}
                        className={`pdf-page`}/>
                </Document>
            </div>
            <div className={'d-flex justify-content-between align-content-center mt-1'}>
                <form onSubmit={(e) => onSubmitForm(e)} id={`create-user-form`}>
                    <div className="form-group d-flex justify-content-start align-content-center pb-2">
                        <label htmlFor="exampleInputName" className={'m-2'}>
                            Show:
                        </label>
                        <input
                            type="text"
                            className="form-control w-25"
                            id="exampleInputName"
                            aria-describedby="nameHelp"
                            onChange={handleInput}
                            placeholder="Page No"
                            name="pageNo"
                            required
                        />
                        <button type={'submit'} className={'btn btn-sm text-primary btn-light ms-2'}>
                            <b>Go</b>
                        </button>
                    </div>
                </form>
                <div className={`d-flex justify-content-center align-items-center pb-2`}>
                    {page - 10 > 0 ?
                        <button type={`button`} className={`btn btn-sm me-1`} onClick={() => goLeft(10)}>
                            <span className={`bi bi-chevron-left`}/> {page - 10}
                        </button> : null}
                    <button type={`button`} className={`btn btn-sm me-1`} onClick={() => goLeft(1)}>
                        <span className={`bi bi-chevron-left`}/>
                    </button>
                    <small>
                        {totalPages > 0 ? (
                            <div>
                                <span className="text-decoration-underline">{page}</span> of {totalPages}
                            </div>
                        ) : null}
                    </small>
                    <button type={`button`} className={`btn btn-sm ms-1`} onClick={() => goRight(1)}>
                        <span className={`bi bi-chevron-right`}/>
                    </button>
                    {page + 9 < totalPages ?
                        <button type={`button`} className={`btn btn-sm ms-1`} onClick={() => goRight(10)}>
                            {page + 10}
                            <span className={`bi bi-chevron-right`}/>
                        </button> : null}
                </div>
            </div>
        </div>
    );
};
export default PdfRenderer;
