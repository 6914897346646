import React, {FormEvent, useEffect, useState} from "react";
import {Button} from "../../../components/button/Button";
import {NetworkState} from "../../../network/dto/ApiContract";
import {useApiClient} from "../../../network/client/UseApiClient";
import UploadFiles from "../../../components/upload/files/FilesUpload";
import {getOrgDetails, UpdateOrgDetails} from "../../../services/OrganizationService";
import {Organization} from "../../../models/Organization";
import {useInputHandler} from "../../../hooks/UseInputHandler";
import {v4} from "uuid";
import {toast} from "../../../components/toast/ToastManager";


export const BrandSetting = () => {
    const [accountData, accountDataNetworkState, AccountError, fetchData] = useApiClient<Organization>();
    const [data, dataNetworkState, error, updateOrganizationDetails] = useApiClient<Organization>();
    const {payload, handleChange, setValue, setData} = useInputHandler<Organization>({});
    const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
    const [isUploaded, setIsUploading] = useState<boolean>(false);


    useEffect(() => {
        fetchData(getOrgDetails()).then(res=> {
            setData(res)
        });
    }, []);

    const submit = (event: FormEvent)=> {
        event.preventDefault();
        updateOrganizationDetails(UpdateOrgDetails(payload)).catch(err=> {
            if (err.response.status !== 403)
                toast.show({id: v4(), title: `Error: ${err.toString()}`, duration: 5000, isSuccess: false})
        }).then(res=> {
            toast.show({id: v4(), title: `Brand Details Saved Successfully`, duration: 4000, isSuccess: true})
        });
    }

    const handleFileChange = (file: File | null) => {
        if (file) {
            setUploadingFiles([...uploadingFiles, file]);
            setIsUploading(true);
        }
    };
    return <div className={'mt-4'}>
        <div className={`w-`}>
            <div className={`row mb-3`}>
                <div className={`col-4 col-md-3 col-lg-2`}>
                    <span className={`text-secondary col-6 col-sm-3 col-md-4 col-lg-2 small fw-bold`}>Brand Details</span>
                </div>
                <div className={`col-7 col-md-8 col-lg-9`}>
                    <div className={'bg-white rounded px-4 py-3'}>
                        <div className={'myAccount d-flex justify-content-center align-items-center m-4 col-8'}>
                            <form id={`form_org_info`} className={'flex-fill'} onSubmit={submit}>
                                <div className="form-group mt-2 ">
                                    <label className={'small mb-2'}><b>Brand name <span className='text-danger'>*</span></b></label>
                                    <input type="text" className="form-control rounded-0" id="exampleInputName"
                                           aria-describedby="nameHelp"
                                           value={payload?.name || ''} name="name" required={true}
                                           onChange={handleChange}
                                           placeholder={'Brand name'}/>
                                </div>
                                <div className="form-group mt-2">
                                    <label className={'small mb-2'}><b>Brand email <span className='text-danger'>*</span></b></label>
                                    <input type={'email'} className="form-control rounded-0" id="exampleInputName"
                                           aria-describedby="nameHelp" placeholder={'Email'}
                                           onChange={handleChange}
                                           value={payload?.email || ''} name="email"/>
                                </div>
                                <div className="form-group mt-2 ">
                                    <label className={'small mb-2'}><b>Website</b></label>
                                    <input type="url" className="form-control rounded-0" id="exampleInputName"
                                           aria-describedby="nameHelp" placeholder={'Brand Website'}
                                           onChange={handleChange}
                                           value={payload?.website || ''} name="website" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`row`}>
                <div className={`col-4 col-md-3 col-lg-2`}>
                    <span className={`text-secondary small fw-bold`}>Logo and Color Customization</span>
                </div>
                <div className={`col-7 col-md-8 col-lg-9`}>
                    <div className={`bg-white rounded px-4 py-3`}>
                        <div className="form-group m-4 col-4">
                            <label className={'small mb-2'}><b>Logo</b></label>
                            <UploadFiles defaultImageUrl={payload?.logoImageUrl || null} onDone={(res) => {
                                setValue("logoImageUrl", res.key);
                            }}
                                         // onFileChange={handleFileChange}
                                onFileChange={()=>{
                                    setValue('logoImageUrl','')
                                }}
                            />
                        </div>
                    </div>
                    <div className={'d-flex justify-content-between mt-4'}>
                        <div></div>
                        <Button form={`form_org_info`} loaderText={`Saving brand settings...`}
                                loading={dataNetworkState === NetworkState.Loading}
                                className={`btn btn-primary h-10 w-20`}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default BrandSetting;