import {ApiContract} from "../network/dto/ApiContract";

const getProjects = () : ApiContract => {
    return {url : `/projects` , method : "GET"}
}

const createNewProject=(payload?:any):ApiContract=>{
    return {url:`/projects`,method:'POST',requestBody:payload}
}

const updateProject=(payload?:any,projectId?:string):ApiContract=>{
    return {url:`/projects/${projectId}`,method:'PUT',requestBody:payload}
}

const GetSingleProject=(projectId:string):ApiContract=>{
    return {url:`/project/${projectId}`,method:'GET'}
}

const DeleteProjectApiContract=(id?:string):ApiContract=>{
    return {url:`/projects/${id}`,method:'DELETE'}
}

const UploadFile =(file:File):ApiContract=>{
    const formData = new FormData();
    formData.append('file', file);
    return {url:`/api/public/files/upload` ,method:"POST",requestBody:formData,config: {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }}
}




export {getProjects,createNewProject,GetSingleProject,DeleteProjectApiContract,updateProject,UploadFile}