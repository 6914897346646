import sortOrder from "../../../../../../assets/icons/sort_order.svg";
import React, {Fragment, useEffect, useState} from "react";
import {ProjectClientListCard} from "./ProjectClientListCard/ProjectClientListCard";
import '../projectClients/ProjectClientListCard/projectClientListCard.scss'
import {useApiClient} from "../../../../../../network/client/UseApiClient";
import {GetAllProjectClients} from "../../../../../../services/ClientService";
import {useParams} from "react-router";
import {Client} from "../../../../../../models/Client";
import {NetworkState} from "../../../../../../network/dto/ApiContract";
import {Spinner} from "../../../../../../components/spinner/Spinner";
import {AddNewClient} from "./addNewClient/AddNewClient";
import {EmptyState} from "../../../../../../components/emptyState/EmptyState";


export const ProjectClients = () => {
    const [projectsClients, projectsClientsNetworkState, error, fetchProjectsClients] = useApiClient<Client[]>();
    const params = useParams<{ projectId: string }>();
    const [activeClient, setActiveClient] = useState<Client>();
    const[showModal,setShowModal]=useState<boolean>(false);
    
    
    useEffect(() => {
        fetchProjectsClients(GetAllProjectClients(+(params?.projectId || 1)))

    }, [params?.projectId]);

    return <div className={'mt-1'}>
        <div className={``}>
            <div className={`d-flex`}>
                <button className={`btn btn-primary d-flex`} onClick={()=>setShowModal(true)}>
                    <img src={''} className={'uploadIcon me-1'} />
                    + New Owner
                </button>
                <div className={`ms-3 d-flex align-items-center`} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <img src={sortOrder} className={'me-2'}/>
                    <small>Sort by: Most Recent</small>
                </div>
            </div>
            {projectsClientsNetworkState=== NetworkState.Loading?<div className={`w-100 d-flex justify-content-center`}>
                    <Spinner/>
                </div>:
            <div className={'clients mt-4'}>
                {projectsClients?.length  ? <Fragment>
                {projectsClients?.map((data: Client) => <ProjectClientListCard key={data.id} projectId={params.projectId||''} data={data} onDelete={()=>{
                    fetchProjectsClients(GetAllProjectClients(+(params?.projectId || 1)))
                }} isActive={activeClient?.id === data.id} onClick={()=> {
                    if (activeClient?.id === data.id) {
                        setActiveClient(undefined)
                    } else
                        setActiveClient(data)
                }} onSuccess={()=>{
                    fetchProjectsClients(GetAllProjectClients(+(params?.projectId || 1)))

                }
                }/>)}</Fragment> : <div className={'container bg-white h-100'}><EmptyState/></div>}
            </div>}
        </div>
        <AddNewClient show={showModal} onCancel={()=>setShowModal(false)} onSuccess={()=> {
            setShowModal(false);
            fetchProjectsClients(GetAllProjectClients(+(params?.projectId || 1)))
        }}/>
    </div>
}