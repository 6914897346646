import {Button} from "../../../components/button/Button";
import {NetworkState} from "../../../network/dto/ApiContract";
import {useInputHandler} from "../../../hooks/UseInputHandler";
import {PasswordResetRequestPayload, Token} from "../../../models/LoginModels";
import {useApiClient} from "../../../network/client/UseApiClient";
import {useNavigate} from "react-router-dom";
import {FormEvent} from "react";
import {PasswordResetRequestApiContract} from "../../../services/LoginService";
import {v4} from "uuid";
import {useToast} from "../../../components/toast/ToastManager";

export const ResetPasswordRequest = () => {
    const {payload, handleChange} = useInputHandler<PasswordResetRequestPayload>({username: ''});
    const [token, loginApiState, loginError, executeLoginApi] = useApiClient<any>();
    const toastManager = useToast();
    const navigate = useNavigate();

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        executeLoginApi(PasswordResetRequestApiContract({username: payload.username})).then(res=> {
            toastManager.show({id: v4(), title: "Password reset link sent on you Email address.", duration: 5000})
        }).catch(err=> {
            // toastManager.show({id: v4(), title: `Error: ${err.message}`, duration: 5000, isSuccess: false})
        });
    }
    return <div className={`h-100 w-100 d-flex align-self-center justify-content-center`}>
        <div className={`loginPanel shadow h-auto align-self-center`}>
            <h4 style={{fontWeight: '500'}} className={`text-center text-primary`}>Reset Password | Dodda-web Portal</h4>
            {loginError ? <div className={`text-danger mb-2`}>Error: {(loginError.response?.data as any)[`details`][0]} </div> : null}
            <form onSubmit={onSubmit} className={`mt-3`}>
                <div className={'mb-3'}>
                    <label htmlFor={`username`} className={`form-label`}>Username</label>
                    <input className={`form-control`} type={`text`} id={`username`} name={`username`}
                           value={payload.username} onChange={handleChange} required/>
                </div>
                <div className={'d-flex justify-content-between'}>
                    <div className={`align-self-center fw-normal`}>Already have an account? <span className={`text-decoration-underline text-primary cursor-pointer`} onClick={()=> {
                        navigate("/login", {replace: true})
                    }}>Login</span></div>
                    <Button loading={loginApiState === NetworkState.Loading} className={`btn btn-primary`} loaderText={`Sending...`}>Send Link</Button>
                </div>
            </form>
        </div>
    </div>
}