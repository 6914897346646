import {ApiContract} from "../network/dto/ApiContract";

const GetAllProjectClients = (id:number): ApiContract => {
    return {url: `/project/${id}/client`, method: "GET"}
}
const GetSingleClient =(id:string):ApiContract=>{
    return {url:`/project/client/${id}`, method:'GET'}
}
const AddClient=(id:string,name?:string,email?:string):ApiContract=>{
    const formData=new FormData();
    formData.append('name',name ||'');
    formData.append('email',email || '');
    return {url:`/project/${id}/client`,method:'POST',requestBody:formData}
}

const updateClient=(id?:string,name?:string,email?:string):ApiContract=>{
    const formData=new FormData();
    formData.append('name',name ||'');
    formData.append('email',email || '');
    return {url:`/clients/${id}`,method:'PUT',requestBody:formData}
}

const RemoveClient=(clientId?:string):ApiContract=>{

    return {url:`/clients/${clientId}`,method:'DELETE'}
}


const DeleteChatSummary=(chatSummaryId?:string):ApiContract=>{
    return {url:`/chat_summary/${chatSummaryId}`,method:'DELETE'}
}

export {GetAllProjectClients,GetSingleClient,AddClient,RemoveClient,DeleteChatSummary,updateClient}