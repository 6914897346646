import {ApiContract} from "../network/dto/ApiContract";
import {UserPasswordRequest, UsersModel} from "../models/UsersModel";

const getUserApiContract = (page?: number, size?: number): ApiContract => {
    return {
        url: `/api/admin/users`, method: "GET", config: {
            params: {
                page: page,
                size: size,
            }
        }
    }
}

const createUserApiContract = (payload: UsersModel): ApiContract => {
    return {url: `/api/admin/users`, method: "POST", requestBody: payload}
}

const editUserApiContract = (id: string, payload: UsersModel): ApiContract => {
    return {url: `/api/admin/users/${id}`, method: "PUT", requestBody: payload}
}

const getSingleUserApiContract = (id: string): ApiContract => {
    return {url: `/api/admin/users/${id}`, method: "GET"}
}
const getProjectUserApiContract = (projectId: string,docId:string): ApiContract => {
    return {url: `/api/admin/project/${projectId}/users`, method: "GET",config:{
        params:{
            docId: docId
        }
        }}
}

const softDeleteUserApiContract = (id ?: string): ApiContract => {
    return {url: `/api/admin/users/${id}/active`, method: "DELETE"}
}

const undoSoftDeleteUserApiContract = (id ?: string): ApiContract => {
    return {url: `/api/admin/users/${id}/active`, method: "POST"}
}

const hardDeleteUserApiContract = (id ?: string): ApiContract => {
    return {url: `/api/admin/users/${id}`, method: "DELETE"}
}

const resetUserPasswordApiContract = (id ?: number | undefined, payload ?: UserPasswordRequest): ApiContract => {
    return {url: `/api/admin/users/${id}/reset-password`, method: "POST", requestBody: payload}
}

export {
    getUserApiContract, createUserApiContract, editUserApiContract, getSingleUserApiContract,getProjectUserApiContract,
    softDeleteUserApiContract, undoSoftDeleteUserApiContract, resetUserPasswordApiContract, hardDeleteUserApiContract
}