import {Fragment, useState} from "react";
import React, {RefObject} from "react";
import Markdown from "react-markdown";
import moment from "moment";
import {useApiClient} from "../../../network/client/UseApiClient";
import {DeleteChatSummary} from "../../../services/ClientService";
import ConfirmationModal from "../../confirmationDialog/ConfirmationModal";
import menu from '../../../assets/icons/menu.svg';

export const PartnerChatSummaries = (props: {data: any[]}) => {
    const [chat, chatNetworkState, chatError, removeChat] = useApiClient<any>();
    const[showConfirmationDialog,setShowConfirmationDialog]=useState<boolean>(false);
    const deleteChatSummary=(id?:string)=>{
       removeChat(DeleteChatSummary(id))

    }

    return <Fragment>

        {props.data.length>0?props.data.map(summary=>
            <div className={`mb-3 border-bottom`}>
                <Markdown>{summary.summary}</Markdown>

                <div className={'d-flex justify-content-between'}><strong>{moment(summary?.createdAt).format('DD MMM YYYY HH:mm')}</strong><p><img src={menu} onClick={()=>setShowConfirmationDialog(true)} style={{cursor:'pointer'}}/> </p></div>

                {/*<div className={'d-flex  card-body shadow m-4'}>*/}
                {/*    <div className={'m-4 padding-4'}>*/}
                {/*        <div className={'d-flex justify-content-between'}><strong>{moment(summary?.createdAt).format('DD MMM YYYY HH:mm')}</strong>*/}
                {/*            <p><img src={menu} onClick={()=>setShowConfirmationDialog(true)}/> </p>*/}
                {/*        </div>*/}

                {/*        <Markdown className={''}>{summary.summary}</Markdown>*/}
                {/*    </div>*/}

                {/*</div>*/}
                <ConfirmationModal title={'Delete this chat summary'} message={'Are you sure you want to delete this?'} onSubmit={()=>{
                    deleteChatSummary(summary.id);
                    setShowConfirmationDialog(false);


                }} onCancel={()=>setShowConfirmationDialog(false)} show={showConfirmationDialog} btnText={'Delete'}/>

            </div>
        ):<label className={`mt-2 text-decoration-underline`}>No Data</label>}


    </Fragment>;
}