import {Chatroom} from "./Chatroom";
import {useApiClient} from "../../network/client/UseApiClient";
import {useParams} from "react-router";

export const ChatPage = () => {
    const {chatId} = useParams<{chatId: string}>();
    const [chatIdRes, initiateChatState, initiateChatError, initiateChat] = useApiClient();

    return <div className={`h-100 overflow-hidden`}>
        {chatId ? <Chatroom brainId={''} chatId={chatId} onHide={()=> {}}/> : null}
    </div>
}