import React from "react";


export const EmptyState =()=>{



    return <div className={'d-flex justify-content-center align-items-center'} >

        <img style={{
            height:'20%', width:'30%'
        }} src={'https://static.vecteezy.com/system/resources/thumbnails/007/746/382/small/drag-and-drop-add-document-file-button-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg'}/>
    </div>
}