export const ChatBubbleRight = (props: { children: any }) => {
    return <div className={`chatBubbleRight`}>
        <div className={`chatContent`}>
            {props.children}
        </div>
        <div className={`chatUserInfo`}>
            <div className={`chatUserIndicator`}><img src={``}/>
            </div>
            <span>You</span>
        </div>
    </div>
}